<h3>Edit Program</h3>
<h4>An English Program name is required. Any languages with 'Automatically update' checked will automatically translate
  through google translate when the program is updated</h4>

<div *ngIf="translatedPrograms['en']">
  <dt>
    <label>English:</label>
  </dt>
  <dd>
    <label>Program Name</label>
  </dd>
  <dd>
    <input type="text" *ngIf="true" [ngClass]="{ error: error }" [(ngModel)]="translatedPrograms['en'].Summary" />
  </dd>
  <dd>
    <label>Description</label>
  </dd>
  <dd>
    <textarea class="description" type="text" [ngClass]="{ error: error }" [(ngModel)]="translatedPrograms['en'].Description"></textarea>
  </dd>
</div>


<div *ngFor="let language of languages">
  <div *ngIf="language !== 'en' && translatedPrograms[language]">
    <dt>
      <label>{{language}}:</label>
    </dt>
  
    <dd>
      <input class="checkbox" type="checkbox" [checked]="!translatedPrograms[language]?.userUpdated"
        (change)="translatedPrograms[language].userUpdated = !translatedPrograms[language]?.userUpdated" />
      Automatically Update
    </dd>
    <dd>
      <label>Program Name</label>
    </dd>
    <dd>
      <input type="text" [ngClass]="{ error: error }" [(ngModel)]="translatedPrograms[language].Summary" />
    </dd>
    <dd>
      <label>Description</label>
    </dd>
    <dd>
      <textarea class="description" type="text" [ngClass]="{ error: error }"
        [(ngModel)]="translatedPrograms[language].Description"></textarea>
    </dd>
  </div>

</div>

<dt>
  <label>Organization</label>
</dt>
<dd>
  <mat-select [(value)]="selectedOrganizationId" name="organization">
    <mat-option *ngFor="let org of organizations" value="{{ org.key }}">
      {{ org.Name }}
    </mat-option>
  </mat-select>
</dd>

<dt>
  <label>Phone Number</label>
</dt>
<dd>
  <input type="text" [(ngModel)]="call" />
</dd>

<dt>
  <label>Address</label>
</dt>
<dd>
  <input class="address" type="text" [(ngModel)]="address" />
</dd>

<dt>
  <label>Website</label>
</dt>
<dd>
  <input type="text" [(ngModel)]="website" />
</dd>

<dt>
  <label>Email</label>
</dt>
<dd>
  <input type="text" [(ngModel)]="email" />
</dd>



<dt>
  <label>Category</label>
</dt>
<dd>
  <mat-select (selectionChange)="categorySelected()" [(value)]="selectedCategoryId" name="category">
    <mat-option *ngFor="let cat of categories" value="{{ cat.key }}">
      {{ cat.Name }}
    </mat-option>
  </mat-select>
</dd>

<dt>
  <label>Subcategory</label>
</dt>
<dd *ngIf="showEmptySubcategoriesWarning">
  (No subcategories)
</dd>
<dd *ngIf="!showEmptySubcategoriesWarning">
  <mat-select [(value)]="selectedSubcategories" name="subcategories" multiple>
    <mat-option *ngFor="let sub of subcategoriesArray" value="{{ sub.key }}">
      {{ sub.Name }}
    </mat-option>
  </mat-select>
</dd>

<br />
<p class="alert">{{ message }}</p>
<button (click)="cancel()" class="cancel">Cancel</button>
<button (click)="updateProgram()" class="submit-update">Update</button>
<br />