<div class="header-case">
  <mat-toolbar>
    <mat-toolbar-row class="header">
      <img class="logo" *ngIf="adminLogo" src="{{ adminLogo }}" alt="MyLNK logo" />
      <a *ngIf="auth.isLoggedIn" routerLink="/home/search" routerLinkActive="active_route">Home</a>
      <a *ngIf="auth.isLoggedIn" routerLink="/home/program-list" routerLinkActive="active_route">Programs</a>
      <a *ngIf="auth.isLoggedIn" routerLink="/home/organizations" routerLinkActive="active_route">Organizations</a>
      <a *ngIf="auth.isLoggedIn" routerLink="/home/categories" routerLinkActive="active_route">Categories</a>
      <a *ngIf="auth.isLoggedIn" routerLink="/home/missingData" routerLinkActive="active_route">Missing Data</a>
      <a *ngIf="auth.isLoggedIn" routerLink="/home/generatePDF" routerLinkActive="active_route">Export Data</a>
      <a *ngIf="auth.isLoggedIn" routerLink="/home/themeCustomization" routerLinkActive="active_route">Customize
        Theme</a>
      <a *ngIf="auth.isLoggedIn" routerLink="/account" routerLinkActive="active_route">Manage
        Account{{ auth.isAdmin ? 's' : '' }}</a>
      <a *ngIf="auth.isLoggedIn" (click)="logout()" routerLink="/login" routerLinkActive="active_route">Logout</a>
      <a id="entity" *ngIf="auth.isLoggedIn">Current Entity:
      </a>
      <select (change)="changeLocation($event.target.value)" [(ngModel)]="currentEntityKey" id="dropdown"
        *ngIf="entities && auth.isLoggedIn">
        <option *ngFor="let item of entities" [value]="item.key">
          {{locationNameMapper[item.key]}}
        </option>
      </select>

    </mat-toolbar-row>
  </mat-toolbar>

  <div class="ext-content">
    <router-outlet></router-outlet>
  </div>
</div>
