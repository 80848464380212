<h3>Add Program</h3>

<div class="content">
  <p class="alert">{{ summaryError }}</p>
  <p class="alert">{{ descriptionError }}</p>
  <p class="alert">{{ categoryError }}</p>

  <div class="form-group">
    <dt>
      <label>Summary*</label>
    </dt>
    <dd>
      <input [ngClass]="{ error: error }" type="text" [(ngModel)]="summary" />
    </dd>
  </div>

  <div *ngIf="id === undefined" class="form-group">
    <dt>
      <label>Organization</label>
    </dt>
    <dd>
      <ng-select
        [items]="organizations | async"
        bindLabel="Name"
        [(ngModel)]="categoryId"
        bindValue="$key"
      >
      </ng-select>
    </dd>
  </div>

  <div class="form-group">
    <dt>
      <label>Phone Number</label>
    </dt>
    <dd>
      <input type="text" [(ngModel)]="call" />
    </dd>
  </div>

  <div class="form-group">
    <dt>
      <label>Address</label>
    </dt>
    <dd>
      <textarea class="address" type="text" [(ngModel)]="address"></textarea>
    </dd>
  </div>

  <div class="form-group">
    <dt>
      <label>Website</label>
    </dt>
    <dd>
      <input type="text" [(ngModel)]="website" />
    </dd>
  </div>

  <div class="form-group">
    <dt>
      <label>Email</label>
    </dt>
    <dd>
      <input type="text" [(ngModel)]="email" />
    </dd>
  </div>

  <div class="form-group">
    <dt>
      <label>Description*</label>
    </dt>
    <dd>
      <textarea
        [ngClass]="{ error: error }"
        class="description"
        type="text"
        [(ngModel)]="description"
      ></textarea>
    </dd>
  </div>

  <div class="form-group">
    <dt>
      <label>Category*</label>
    </dt>
    <dd>
      <ng-select
        [items]="categories"
        bindLabel="Name"
        [(ngModel)]="categoryId"
        bindValue="$key"
        (ngModelChange)="updateSubcategories()"
      >
      </ng-select>
    </dd>
  </div>

  <div class="form-group">
    <dt>
      <label>Subcategory</label>
    </dt>
    <dd>
      <ng-select
        [items]="subcategories | async"
        [multiple]="true"
        [hideSelected]="true"
        bindLabel="Name"
        bindValue="$key"
        [(ngModel)]="subcategoryIds"
      >
      </ng-select>
    </dd>
  </div>
</div>
<button (click)="add()">Add</button>
<br />

<a class="sub" routerLink="/home/org-detail/{{ id }}" routerLinkActive="active"
  >Cancel</a
>
