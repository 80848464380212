<div *ngIf="appName">
  <h3>Welcome to MyLink Admin</h3>
  <div class="content">
    <p>Use this site to:</p>
    <ul>
      <li>View and Edit Categories</li>
      <li>View and Edit Organizations</li>
      <li>View and Edit Programs</li>
    </ul>
    <p>Note: The summary of a program is being used as the program name</p>

    <a *ngIf="!delete" class="alert" (click)="deleting()">Delete Account</a>
    <div *ngIf="delete">
      <p class="alert">
        Are you sure you want to delete your account? This is irreversible.
      </p>
      <button (click)="deleteUser()">Yes</button>
      <button (click)="notDeleting()">No</button>
    </div>
  </div>
</div>