// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCNQC6CgYw2MKSFXkZqeH4MiF4mSCJZrZ4',
    authDomain: 'mylnk-qa-1234.firebaseapp.com',
    databaseURL: 'https://mylink-qa.firebaseio.com',
    projectId: 'mylnk-qa-1234',
    storageBucket: 'mylnk-qa-1234.appspot.com',
    messagingSenderId: '608467109021',
  },
  translateKey: 'ca3ab6e84b2c4c0299cb8c9340a9e359',
  googleMapsApiKey: 'AIzaSyB02uJWNaTpxDIk2ktNryo3zpfhrs8odXE'
};
