import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Program } from '../Models/program.model';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { ObjectCache } from '../Models/objectCache.model';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramService extends DataService<Program> {
  constructor(private db: AngularFireDatabase, private ps: PreferencesService) {
    super(db, `entities/${ps.getLocationCode()}/Data/en/ProgramList`, new ObjectCache<Program>());
  }

  async getProgramsArray(): Promise<Program[]> {
    let results: Program[] = [];
    const result = await super.getRawDataFromPath(this.PATH);
    for (let i in result) {
      result[i].Id = i;
      results.push(result[i]);
    }
    return results;
  }

  async getSingleProgram(id: string, lang?: string): Promise<Program> {
    let path = this.PATH + '/' + id;
    if (lang) {
      path = path.replace('/en/', `/${lang}/`);
    }
    const result = await super.getRawDataFromPath(path);
    let results: Program;
    results = result;
    return results;
  }

  getCachedPrograms(lang?: string): Program[] {
    return super.getCachedObjects(lang);
  }

  getProgramById(id: string, lang?: string): Observable<Program> {
    return super.getObjectById(id, lang);
  }

  getPrograms(lang?: string): Observable<Program[]> {
    return super.getObjects(lang);
  }

  getUpdatablePrograms(lang?: string): AngularFireList<Program> {
    return super.getAngularFireObjects(lang);
  }

  getProgramsWhere(filter: Function, lang?: string): Observable<Program[]> {
    return super.getObjectsWhere(filter, lang);
  }

  getProgramsBySubcategory(
    subcategoryId: string,
    categoryId: string,
    lang?: string
  ): Observable<Program[]> {
    return this.getProgramsWhere(
      (program) =>
        (program.SubcategoryId === subcategoryId ||
          (program.SubcategoryIds !== undefined &&
            program.SubcategoryIds.includes(subcategoryId))) &&
        program.CategoryId === categoryId,
      lang
    );
  }

  async findPrograms(lang?: string): Promise<Program[]> {
    return await super.findCollection(lang);
  }
}
