import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from './organization';
import { Program } from '../Models/program.model';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { TranslateService } from '../firebase/translate.service';
import { Observable } from 'rxjs';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';

@Component({
  selector: 'org-detail',
  templateUrl: './orgDetail.component.html',
  styleUrls: ['./orgDetail.component.css'],
})

// add program button is commented out in html, we may come back and fix this page in the future
export class OrgDetailComponent implements OnInit {
  showTimeStamp = true; // an NGif statement doesn't work for whatever reason

  organization: Organization;
  translatedOrganizations = {};

  languages: string[];
  programs: Observable<Program[]>;
  id: string;
  message: string;

  constructor(
    private translate: TranslateService,
    private programAccessor: ProgramService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: FirebaseAuthService,
    private organizationAccessor: OrganizationService
  ) { }

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.languages = await this.translate.parseLanguages();

    await this.fetchOrgs();
    this.programs = this.programAccessor.getProgramsWhere(
      (program: Program) => program.OrganizationId === this.id
    );
  }

  async fetchOrgs() {
    this.organization = await this.organizationAccessor
      .getOrganizationById(this.id)
      .take(1)
      .toPromise();

    this.languages.forEach((language) => {
      this.translatedOrganizations[
        language
      ] = this.organizationAccessor.getOrganizationById(this.id, language);
    });
    if (this.organization) {
      this.showTimeStamp = !!this.organization.Timestamp;
    }
  }

  verifyInformation() {
    this.organizationAccessor.getUpdatableOrganizations().update(this.id, {
      Timestamp: {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: '',
      },
    });

    this.translate.translateOrganization(this.organization, this.id, false);
  }

  deleteOrg() {
    this.programs.subscribe((programs) => {
      if (
        confirm(
          'Are you sure you want to delete this organization from the database?'
        )
      ) {
        if (programs.length > 0) {
          this.message =
            'Organization cannot be deleted because of dependent programs';
        } else {
          this.organizationAccessor.getUpdatableOrganizations().remove(this.id);
          this.translate.removeOrganization(this.id);
          this.router.navigate(['/home/organizations']);
        }
      }
    });
  }
}
