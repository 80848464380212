import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { FirebaseAuthService } from './firebase/firebaseAuth.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: FirebaseAuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.afAuth.authState
      .take(1)
      .map((authState) => !!authState)
      .do((auth) => (!auth ? this.router.navigate(['/login']) : true));
  }

  canActivateChild(): Observable<boolean> {
    return this.authService.afAuth.authState
      .take(1)
      .map((authState) => !!authState)
      .do((auth) => (!auth ? this.router.navigate(['/login']) : true));
  }
}
