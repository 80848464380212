<h3>Edit Category</h3>

<div class="content">

  <div *ngIf="translatedCategories['en']">
    <dt>
      <label>English:</label>
    </dt>
    <dd>
      <label>Category Name</label>
    </dd>
    <dd>
      <input type="text" *ngIf="true" [ngClass]="{ error: error }" [(ngModel)]="translatedCategories['en'].Name" />
    </dd>
  </div>
  
  
  <div *ngFor="let language of languages">
    <div *ngIf="language !== 'en' && translatedCategories[language]">
      <dt>
        <label>{{language}}:</label>
      </dt>
    
      <dd>
        <input class="checkbox" type="checkbox" [checked]="!translatedCategories[language]?.userUpdated"
          (change)="translatedCategories[language].userUpdated = !translatedCategories[language]?.userUpdated" />
        Automatically Update
      </dd>
      <dd>
        <label>Category Name</label>
      </dd>
      <dd>
        <input type="text" [ngClass]="{ error: error }" [(ngModel)]="translatedCategories[language].Name" />
      </dd>
    </div>
  
  </div>


  <dl>
    <dt>
      <label>Category Icon: </label>
      <a href="https://material.io/tools/icons/" target="_blank"
        >Category Guide</a
      >
    </dt>
    <dd>
      <input [(ngModel)]="categoryIcon" />
    </dd>
  </dl>
</div>
<br />
<span>
  <button
    routerLink="/home/subcategories/{{ id }}"
    routerLinkActive="active"
    class="cancel"
  >
    Cancel
  </button>
  <button
    routerLink="/home/subcategories/{{ id }}"
    routerLinkActive="active"
    (click)="updateAll()"
    class="submit-update"
  >
    Update
  </button>
</span>
