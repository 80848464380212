import { Timestamp } from '../programs/timestamp';
import { coordinate } from '../programs/coordinate';

export class Program {
  Address?= '';
  Call?= '';
  CategoryId?= '';
  Description?= '';
  Email?= '';
  OrganizationId?= '';
  SubcategoryId?= '';
  SubcategoryIds?: string[];
  Summary?= '';
  Website?= '';
  Timestamp?: Timestamp;
  Coordinates?: coordinate;
  key?;
  userUpdated?: boolean;
}

export class Language {
  summary: string;
  description: string;
  userUpdated: boolean;
}
