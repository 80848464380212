import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Organization } from './organization';
import { Timestamp } from '../programs/timestamp';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { GeocodeService } from '../Services/geocode.service';
import { TranslateService } from '../firebase/translate.service';
import { OrganizationService } from '../Services/organization.service';
import { Observable } from 'rxjs';
import { Language } from 'app/Models/program.model';

@Component({
  selector: 'app-edit-organization',
  templateUrl: './editOrganization.component.html',
  styleUrls: ['./editOrganization.component.css']
})
export class EditOrganizationComponent implements OnInit {
  org: Observable<Organization>;

  id: string;
  name: string;
  address: string;
  phoneNumber: string;
  website: string;
  email: string;
  languages: string[];
  translatedOrganization = {};
  translations: Language[] = [];
  timestamp: Timestamp;
  message: string;
  error = false;

  constructor(
    private geocoder: GeocodeService,
    private translateService: TranslateService,
    private auth: FirebaseAuthService,
    private route: ActivatedRoute,
    private organizationAccessor: OrganizationService
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.org = this.organizationAccessor.getOrganizationById(this.id);
    this.org.subscribe((value) => {
      if(value){
        this.name = value.Name;
        this.address = value.Address;
        this.phoneNumber = value.Call;
        this.website = value.Website;
        this.email = value.Email;
      }
    });

    this.languages = await this.translateService.parseLanguages();

    for (const language of this.languages) {
      this.translatedOrganization[
        language
      ] = await this.organizationAccessor.getSingleOrganization(this.id, language);
      if (!this.translatedOrganization[language]) {
        // If organization doesn't exist in language, set display values for edit page
        this.translatedOrganization[language] = {
          Name: '',
          userUpdated: false,
        };
      }
    }
  }

  generateTimestamp(): Timestamp {
    const changes = [];
    this.org.subscribe((organization: Organization) => {
      if (this.name !== organization.Name) {
        changes.push(`Name:${organization.Name}:${this.name}`);
      }
      if (this.address !== organization.Address) {
        changes.push(`Address:${organization.Address}:${this.address}`);
      }
      if (this.phoneNumber !== organization.Call) {
        changes.push(`Phone:${organization.Call}:${this.phoneNumber}`);
      }
      if (this.website !== organization.Website) {
        changes.push(`Website:${organization.Website}:${this.website}`);
      }
      if (this.email !== organization.Email) {
        changes.push(`Email:${organization.Email}:${this.email}`);
      }
    });
    if (changes.length > 0) {
      return {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: changes.join(';'),
      };
    } else {
      return {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: null,
      };
    }
  }

  updateAll() {
    this.timestamp = this.generateTimestamp();

    this.translatedOrganization['en'].Name = this.translatedOrganization[
      'en'
    ].Name.trim();

    this.languages.forEach((language) => {
      this.translations[language] = {
        name: this.translatedOrganization[language].Name,
        userUpdated: this.translatedOrganization[language].userUpdated
      };
    });

    if (this.translatedOrganization['en'].Name.length > 5000) {
      this.message =
        'Name must be less than 5000 characters';
      this.error = true;
    } else {
      const updatedOrganization: Organization = {
        Address: this.address ? this.address.trim() : '',
        Call: this.phoneNumber ? this.phoneNumber.trim() :  '',
        Email: this.email ? this.email.trim() :  '',
        Image: '',
        Name: this.translatedOrganization['en'].Name,
        Website: this.website ? this.website.trim() : '',
        Timestamp: this.timestamp,
      };

      const updatableOrganizations = this.organizationAccessor.getUpdatableOrganizations();
      updatableOrganizations.update(this.id, updatedOrganization);
      this.translateService.translateOrganizationByUserUpdated(updatedOrganization, this.id, this.translations);
    }
  }
}
