import { Component, OnInit } from '@angular/core';
import { Program } from '../Models/program.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CycleList,
  DayList,
  ScheduledEvent,
} from '../scheduled/scheduledEvent';
import { Organization } from '../organizations/organization';
import { Category, Subcategory } from '../categories/category';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { TranslateService } from '../firebase/translate.service';
import { Observable } from 'rxjs';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';
import { CategoryService } from '../Services/category.service';
import { SubcategoryService } from '../Services/subcategory.service';
import { ScheduleService } from '../Services/schedule.service';
import 'time-input-polyfill';
import * as moment from 'moment';

@Component({
  selector: 'program-detail',
  templateUrl: './programDetail.component.html',
  styleUrls: ['./programDetail.component.css'],
})
export class ProgramDetailComponent implements OnInit {
  showTimeStamp = true;
  languages: string[];

  program: Program;
  translatedSummaries = {};
  translatedDescriptions = {};

  associatedOrganization: Observable<Organization>;
  associatedCategory: Observable<Category>;
  associatedSubcategories: Subcategory[] = [];
  programSchedules: ScheduledEvent[];

  adding = false;
  id: string;
  cycle: string;
  day: string;
  startTime: string;
  endTime: string;
  time: string;
  isSafari: boolean;

  cycleMessage: string;
  dayMessage: string;
  error = false;

  cycles: string[] = CycleList;
  days: string[] = DayList;

  constructor(
    private auth: FirebaseAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private programAccessor: ProgramService,
    private organizationAccessor: OrganizationService,
    private categoryAccessor: CategoryService,
    private subcategoryAccessor: SubcategoryService,
    private scheduleAccessor: ScheduleService
  ) { }

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.languages = await this.translate.parseLanguages();

    this.program = await this.programAccessor
      .getProgramById(this.id)
      .take(1)
      .toPromise();

    this.languages.forEach((language) => {
      this.programAccessor
        .getProgramById(this.id, language)
        .subscribe((program) => {
          if (program) {
            this.translatedSummaries[language] = program.Summary || '';
            this.translatedDescriptions[language] = program.Description || '';
          }
        });
    });
    this.update();

    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
      this.isSafari = true;
    }
  }

  verifyProgramInformation() {
    this.programAccessor.getUpdatablePrograms().update(this.id, {
      Timestamp: {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: '',
      },
    });
  }

  update() {
    const program = this.program;
    if (program) {
      if (program.Timestamp) {
        this.showTimeStamp = true;
      } else {
        this.showTimeStamp = false;
      }
      this.associatedCategory = this.categoryAccessor.getCategoryById(
        program.CategoryId
      );
      this.associatedOrganization = this.organizationAccessor.getOrganizationById(
        program.OrganizationId
      );
      this.associatedSubcategories = [];
      if (program.SubcategoryId) {
        this.subcategoryAccessor
          .getSubcategoryById(program.CategoryId, program.SubcategoryId)
          .subscribe((subcat) => {
            this.associatedSubcategories.push(subcat);
          });
      }
      if (program.SubcategoryIds) {
        program.SubcategoryIds.forEach((subcategory) => {
          if (subcategory !== program.SubcategoryId) {
            this.subcategoryAccessor
              .getSubcategoryById(program.CategoryId, subcategory)
              .subscribe((subcat) => {
                this.associatedSubcategories.push(subcat);
              });
          }
        });
      }

      // note: for schedules, id is the associated program id and key is the event's id in firebase
      this.scheduleAccessor
        .getUpdatableEvents()
        .snapshotChanges()
        .subscribe((schedules) => {
          this.programSchedules = [];
          schedules.forEach((event) => {
            if (event.payload.val().Id === this.id) {
              let e: ScheduledEvent;
              e = event.payload.val();
              e.key = event.key;
              this.programSchedules.push(e);
            }
          });
        });
    }
    this.program = program;
  }

  add() {
    this.adding = true;
    this.cycle = '';
    this.day = '';
    this.startTime = '';
    this.endTime = '';
  }

  edit(key: string) {
    this.scheduleAccessor.findSingleEvent(key);
  }

  addEvent() {
    this.error = false;
    this.cycleMessage = '';
    this.dayMessage = '';
    // time is value read by the old app, needs to be in more readable format.
    // start and end time left in military time for easier sorting on web.
    if (this.cycle === '' || this.day === '' || !this.startTime) {
      this.error = true;
      this.cycleMessage = 'Cycle, day, and start time are required';
    }
    if (!this.error) {
      const timestamp = {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: `Cycle::${this.cycle};Day::${this.day};Time::${this.startTime + ' ' + this.endTime
          }`,
      };
      const startTime = moment(this.startTime, 'HH:mm').format('h:mma');
      let endTime = '';
      if (this.endTime) {
        endTime = ' - ' + moment(this.endTime, 'HH:mm').format('h:mma');
      }

      this.scheduleAccessor.getUpdatableEvents().push({
        Id: this.id,
        Cycle: this.cycle,
        Day: this.day,
        Time: startTime + endTime,
        StartTime: this.startTime,
        EndTime: this.endTime,
        Timestamp: timestamp,
      });
      this.update();
      this.adding = false;
    }
  }

  cancel() {
    this.adding = false;
    this.error = false;
    this.cycleMessage = '';
    this.dayMessage = '';
  }

  deleteEvent(key: string) {
    if (key) {
      this.scheduleAccessor.getUpdatableEvents().remove(key);
      this.update();
    }
  }

  delete() {
    if (
      confirm('Are you sure you want to delete this program from the database?')
    ) {
      this.programAccessor.getUpdatablePrograms().remove(this.id);
      this.programSchedules.forEach((schedule) => {
        this.scheduleAccessor.getUpdatableEvents().remove(schedule.key);
      });
      this.router.navigate(['/home/program-list/']);
      this.translate.removeProgram(this.id);
    }
  }

  toEditProgram() {
    this.router.navigate(['/home/editProgram/' + this.id]);
  }

  displaySchedule(startTime, endTime) {
    if (endTime) {
      return (
        moment(startTime, 'HH:mm').format('h:mma') +
        ' to ' +
        moment(endTime, 'HH:mm').format('h:mma')
      );
    } else {
      return moment(startTime, 'HH:mm').format('h:mma');
    }
  }
}
