import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { User, UserLocation } from 'app/admin/user';
import { UserService } from '../Services/user.service';

@Injectable()
export class FirebaseAuthService {
  user: firebase.User;
  createdUser: firebase.User;
  isLoggedIn: boolean = false;
  isAdmin: boolean = false;
  redirectUrl: string;
  goodMessage: string;
  badMessage: string;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private userAccessor: UserService
  ) {
    this.checkAuthState();
  }

  login(email: string, password: string) {
    this.goodMessage = '';
    this.badMessage = '';
    const self = this;
    this.isAdmin = false;
    localStorage.setItem('userEmail', email);
    this.afAuth.auth.setPersistence('local').then(function () {
      self.afAuth.auth.signInWithEmailAndPassword(email, password).then(
        function (USER) {
          self.userAccessor
            .getUpdatableUsers()
            .valueChanges()
            .subscribe((users) => {
              let isDisabled = false;
              users.forEach((user) => {
                if (user.email == USER.user.email) {
                  if (!user.isEnabled) {
                    isDisabled = true;
                  }
                }
              });
              if (!isDisabled) {
                self.isLoggedIn = true;
                self.userAccessor.admins.valueChanges().subscribe((admins) => {
                  if (admins.map((x) => x.uid).includes(USER.user.uid)) {
                    self.isAdmin = true;
                  }
                });
                self.router.navigate(['/home']);
              } else {
                self.badMessage = 'Your account has been disabled';
                self.isLoggedIn = false;
              }
            });
        },
        function (reason) {
          self.badMessage = reason.message;
        }
      );
    });
  }

  logout() {
    localStorage.setItem('userEmail', '');
    this.afAuth.auth.signOut();
    this.user = null;
    this.isLoggedIn = false;
    this.goodMessage = '';
    this.badMessage = '';
  }

  changePassword(uid, email, currentPassword, newPassword) {
    let self = this;
    if (this.user.uid == uid) {
      const credentials = firebase.auth.EmailAuthProvider.credential(
        email,
        currentPassword
      );
      this.user
        .reauthenticateWithCredential(credentials)
        .then(function () {
          self.user.updatePassword(newPassword);
          self.goodMessage = 'Password change successful!';
        })
        .catch(function (error) {
          self.badMessage = 'Current password is not valid.';
        });
    } else {
      self.badMessage = 'You can only change your password!';
    }
  }

  sendPasswordResetEmail(email) {
    let self = this;
    this.afAuth.auth
      .sendPasswordResetEmail(email)
      .then(function () {
        self.goodMessage = 'Password reset email sent!';
      })
      .catch(function (error) {
        self.badMessage = 'An error occurred sending password reset email.';
      });
  }

  loginAfterCreateAndPushToDatabase(
    currentUser,
    currentPassword,
    newUser,
    isAdmin
  ) {
    const self = this;
    this.afAuth.auth
      .signInWithEmailAndPassword(currentUser, currentPassword)
      .then(
        function (value) {
          self.userAccessor.updateUserList(newUser, isAdmin);
          self.goodMessage = 'User has been created';
        },
        function (reason) { }
      );
  }

  createUser(
    email: string,
    password: string,
    isAdmin: boolean,
    currentUser: string,
    currentPassword: string,
    entities: UserLocation[]
  ) {
    this.goodMessage = '';
    this.badMessage = '';
    const self = this;
    this.afAuth.auth
      .signInWithEmailAndPassword(currentUser, currentPassword)
      .then((value) => {
        const nestedSelf = self;
        self.afAuth.auth
          .createUserWithEmailAndPassword(email, password)
          .then(function (value) {
            let newUser: User = {
              email: value.user.email,
              uid: value.user.uid,
              isAdmin: isAdmin,
              isEnabled: true,
              entities: entities
            };
            nestedSelf.loginAfterCreateAndPushToDatabase(
              currentUser,
              currentPassword,
              newUser,
              isAdmin
            );
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((reason) => {
        console.error(reason);
        self.badMessage = reason.message;
      });
  }

  checkAuthState() {
    this.afAuth.user.subscribe((res) => {
      this.userAccessor.admins.valueChanges().subscribe((admins) => {
        if (res && res.uid) {
          this.isLoggedIn = true;
          this.user = res;
          if (admins.map((x) => x.uid).includes(res.uid)) {
            this.isAdmin = true;
          }
        } else {
          this.isLoggedIn = false;
        }
      });
    });
  }

  deleteUser(key: string, adminKey: string) {
    this.userAccessor.deleteUser(key, adminKey);
    this.user.delete();
    this.isLoggedIn = false;
    this.router.navigate(['/login']);
    this.goodMessage = 'User has been deleted';
  }
}
