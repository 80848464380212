import { ObjectCache } from '../Models/objectCache.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';

const firebase = require('firebase/app');
require('firebase/database')

export class DataService<T> {
  PATH: string;
  cache: ObjectCache<T>;

  static dbRef: firebase.database.Database;

  constructor(
    private database: AngularFireDatabase,
    path: string,
    cache: ObjectCache<T>,
    private storage?: AngularFireStorage
  ) {
    this.PATH = path;
    this.cache = cache;
    this.loadDatabase();
  }

  async getRawDataFromPath(path: string): Promise<any> {
    var ref = firebase.database().ref(path);
    return (await ref.once('value')).val();
  }

  static getHostname() {
    let hostname = window.location.hostname;
    if (hostname.endsWith('.testing'))
      hostname = hostname.substring(0, hostname.length - 7) + 'app';
    if (hostname.startsWith('admin.')) hostname = hostname.substring(6);
    hostname = hostname.replace(/\./g, '-');
    return hostname;
  }

  loadDatabase() {
    if (!DataService.dbRef)
      DataService.dbRef = this.database.database.app.database();
  }

  protected getCachedObjects(lang?: string): T[] {
    const fullLang = lang ? lang : 'en';
    if (this.cache[fullLang]) return this.cache[fullLang];

    let objectList = [];
    DataService.dbRef
      .ref(this.PATH)
      .once('value')
      .then((objectsSnapshot) => {
        objectsSnapshot.forEach((objectSnapshot) => {
          const object = objectSnapshot.val();
          object.key = objectSnapshot.key;
          objectList.push(object);
        });
      });

    this.cache[fullLang] = objectList;

    return objectList;
  }

  protected getObjectById(id: string, lang?: string): Observable<T> {
    let path = `${this.PATH}/${id}`;
    if (lang) {
      path = path.replace('/en/', `/${lang}/`);
    }
    return this.database.object<T>(DataService.dbRef.ref(path)).valueChanges();
  }

  protected getObjects(lang?: string): Observable<T[]> {
    return this.database
      .list<T>(DataService.dbRef.ref(this.PATH))
      .snapshotChanges()
      .pipe(
        map((items) => {
          return items.map((item) => {
            const object = item.payload.val();
            // @ts-ignore
            object.key = item.key;
            return object;
          });
        })
      );
  }

  protected getThemeObjects(lang?: string): Observable<any> {
    return this.database
      .list<any>(DataService.dbRef.ref(this.PATH))
      .snapshotChanges()
      .pipe(
        map((items) => {
          const newList = [];
          items.forEach((item) => {
            const newObject = new Object();
            const object = item.payload.val();

            newObject['Name'] = item.key;
            newObject['Value'] = object;

            newList.push(newObject);
          });
          return newList;
        })
      );
  }

  protected getAngularFireObjects(lang?: string): AngularFireList<T> {
    let path = this.PATH;
    if (lang) {
      path = path.replace('/en/', `/${lang}/`);
    }
    return this.database.list<T>(DataService.dbRef.ref(path));
  }

  protected getAngularFireObjectsByPath(
    path: string,
    lang?: string
  ): AngularFireList<T> {
    let finalPath = `${path}`;
    if (lang) {
      finalPath = finalPath.replace('/en/', `/${lang}/`);
    }
    return this.database.list<T>(DataService.dbRef.ref(finalPath));
  }

  protected getObjectsWhere(filtr: Function, lang?: string): Observable<T[]> {
    return this.database
      .list<T>(DataService.dbRef.ref(this.PATH))
      .snapshotChanges()
      .pipe(
        map((items) => {
          return items
            .map((item) => {
              const object = item.payload.val();
              // @ts-ignore
              object.key = item.key;
              return object;
            })
            .filter((item) => filtr(item));
        })
      );
  }

  protected getList(path: string, lang?: string): Observable<T[]> {
    return this.database.list<T>(DataService.dbRef.ref(path)).valueChanges();
  }

  protected getUpdatableDatabase(): AngularFireObject<any> {
    return this.database.object<any>(DataService.dbRef.ref(this.PATH));
  }


  protected uploadFileToStorage(filePath: string, file: File) {
    return this.storage.upload(filePath, file).snapshotChanges();
  }

  protected getDownloadUrl(fileName: string) {
    return this.storage.ref(fileName).getDownloadURL();
  }

  protected deleteFileFromStorage(fileUrl: string) {
    return this.storage.storage.refFromURL(fileUrl).delete();
  }

  protected async findCollection(lang?: string): Promise<T[]> {
    let path = this.PATH;
    if (lang) {
      path = path.replace('/en/', `/${lang}/`);
    }
    return await this.database
      .list<T>(DataService.dbRef.ref(path))
      .snapshotChanges()
      .pipe(
        map((items) => {
          return items.map((item) => {
            const object = item.payload.val();
            // @ts-ignore
            object.key = item.key;
            return object;
          });
        })
      ).take(1).toPromise();
  }

  protected async findDocument(id: string, lang?: string): Promise<T> {
    let path = `${this.PATH}/${id}`;
    if (lang) {
      path = path.replace('/en/', `/${lang}/`);
    }
    return await this.database.object<T>(DataService.dbRef.ref(path)).valueChanges().pipe().take(1).toPromise();
  }
}
