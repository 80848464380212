import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { SubcategoriesComponent } from './categories/subcategories.component';
import { EditCategoryComponent } from './categories/editCategory.component';
import { EditSubcategoryComponent } from './categories/editSubCategory.component';
import { OrgDetailComponent } from './organizations/orgDetail.component';
import { EditOrganizationComponent } from './organizations/editOrganization.component';
import { ProgramDetailComponent } from './programs/programDetail.component';
import { AddProgramComponent } from './programs/addProgram.component';
import { EditProgramComponent } from './programs/editProgram.component';
import { SearchComponent } from './search/search.component';
import { MissingDataComponent } from './missing-data/missingData.component';
import { ContainerComponent } from './container/container.component';
import { LoginComponent } from './login/login.component';
import { EditScheduleComponent } from './scheduled/editSchedule.component';
import { AdminComponent } from './admin/admin.component';
import { NotAuthorizedComponent } from './notAuthorized/notAuthorized.component';
import { SubProgramComponent } from './categories/subProgram.component';
import { ThemeCustomizationComponent } from './theme-customization/theme-customization.component';

import { AuthGuard } from './auth-guard.service';
import { AdminAuthGuard } from './admin-auth-guard.service';
import { PDFComponent } from './PDF/pdf.component';
import { ProgramListComponent } from './program-list/program-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'login', component: LoginComponent },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  {
    path: 'home',
    component: ContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          { path: 'categories', component: CategoriesComponent },
          { path: 'subcategories/:id', component: SubcategoriesComponent },
          { path: 'editSubcategory/:catId/:subcatId', component: EditSubcategoryComponent},
          {
            path: 'subProgram/:catId/:subcatId',
            component: SubProgramComponent,
          },
          { path: 'organizations', component: OrganizationsComponent },
          { path: 'program-list', component: ProgramListComponent },
          { path: 'org-detail/:id', component: OrgDetailComponent },
          { path: 'org-detail/:id/:q', component: OrgDetailComponent },
          { path: 'editCategory/:id', component: EditCategoryComponent },
          {
            path: 'editOrganization/:id',
            component: EditOrganizationComponent,
          },
          { path: 'program/:id', component: ProgramDetailComponent },
          { path: 'program/:id/:fromCat', component: ProgramDetailComponent },
          { path: 'addProgram', component: AddProgramComponent },
          { path: 'addProgram/:id', component: AddProgramComponent },
          { path: 'editProgram/:id', component: EditProgramComponent },
          { path: 'editProgram/:id/:fromCat', component: EditProgramComponent },
          { path: 'search', component: SearchComponent },
          { path: 'search/:q', component: SearchComponent },
          { path: 'missingData', component: MissingDataComponent },
          { path: 'editSchedule/:id', component: EditScheduleComponent },
          { path: 'generatePDF', component: PDFComponent },
          {
            path: 'themeCustomization',
            component: ThemeCustomizationComponent,
          },
          { path: '', component: HomeComponent },
        ],
      },
    ],
  },
  { path: 'account', component: AdminComponent },
  { path: '**', pathMatch: 'full', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard, AdminAuthGuard],
})
export class AppRoutingModule {}

export const routingComponents = [
  AppComponent,
  HomeComponent,
  CategoriesComponent,
  OrganizationsComponent,
  SubcategoriesComponent,
  EditCategoryComponent,
  EditSubcategoryComponent,
  OrgDetailComponent,
  EditOrganizationComponent,
  ProgramDetailComponent,
  AddProgramComponent,
  EditProgramComponent,
  SearchComponent,
  MissingDataComponent,
  ContainerComponent,
  LoginComponent,
  EditScheduleComponent,
  AdminComponent,
  NotAuthorizedComponent,
  SubProgramComponent,
  PDFComponent,
  ProgramListComponent,
  ThemeCustomizationComponent,
];
