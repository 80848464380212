<h5 class="alert">{{ message }}</h5>
<h3>Categories <a class="sub add" (click)="addingCat()">Add Category</a></h3>
<div>
  <div class="update" *ngIf="adding">
    <h4>Add Category</h4>
    <h5>* denotes a required field. Category name is required in english, but can be specified in additional languages</h5>
    <div>
      <p class="alert">{{ errorMessage }}</p>
      <label>English</label>
      <div class="textbox">
        <label>Category Name*</label>
      </div>
      <div class="textbox">
        <input [ngClass]="{ error: error }" [(ngModel)]="newCategory.Name" />
      </div>

      <div *ngFor="let language of languages">
        <div *ngIf="language !== 'en' && translatedCategories[language]">
          <label>{{language}}:</label>
          <dd>
            <input class="checkbox" type="checkbox" [checked]="!translatedCategories[language]?.userUpdated"
              (change)="translatedCategories[language].userUpdated = !translatedCategories[language]?.userUpdated" />
            Automatically Update
          </dd>
          <div class="textbox">
            <label>Category Name</label>
          </div>
          <div class="textbox">
            <input [ngClass]="{ error: error }" [(ngModel)]="translatedCategories[language].Name" />
          </div>
        </div>
      </div>

      <div>
        <label>Icon</label>
      </div>
      <div>
        <input [ngClass]="{ error: error }" [(ngModel)]="newCategory.Icon" />
      </div>
    </div>

    <br />
    <button (click)="add()">Add</button>
    <br />
    <a class="sub" (click)="cancel()">Cancel</a>
  </div>
  <ul id="menu">
    <li id="row" *ngFor="let item of categories">
      <a routerLink="/home/subcategories/{{ item.key }}">
        <mat-icon>{{ item.Icon }}</mat-icon>
        {{ item.Name }}
      </a>
      <button mat-icon-button id="delete" (click)="delete(item.key)">
        <mat-icon>delete</mat-icon>
      </button>
    </li>
  </ul>
</div>
