import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../firebase/translate.service';
import { Category } from './category';
import { Language, Program } from '../Models/program.model';
import { Router } from '@angular/router';
import { ProgramService } from '../Services/program.service';
import { CategoryService } from '../Services/category.service';

@Component({
  selector: 'categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
})
export class CategoriesComponent implements OnInit {
  categories: Category[];
  newCategory: Category = new Category();
  programs: Program[];
  adding = false;
  message: string;
  errorMessage: string;
  error = false;
  translations: Language[] = [];
  languages: string[];
  translatedCategories = {};

  constructor(
    router: Router,
    private translateService: TranslateService,
    private programAccessor: ProgramService,
    private categoryAccessor: CategoryService
  ) {
    this.categoryAccessor
      .getUpdatableCategories()
      .snapshotChanges()
      .subscribe((categories) => {
        this.categories = [];
        categories.forEach((category) => {
          let c: Category = category.payload.val();
          c.key = category.key;
          this.categories.push(c);
        });
    });
  }

  async ngOnInit() {
    this.programs = this.programAccessor.getCachedPrograms();
    this.languages = await this.translateService.parseLanguages();

    for (const language of this.languages) {
      this.translatedCategories[language] = {
        Name: '',
        userUpdated: false,
      };
    }
  }

  addingCat() {
    this.adding = !this.adding;
    this.newCategory.Name = '';
    this.newCategory.Icon = '';
  }

  add() {
    let newCategory = this.newCategory;
    newCategory.Subcategories = [];
    this.translatedCategories['en'].Name = newCategory.Name
    this.translatedCategories['en'].userUpdated = true
    this.languages.forEach((language) => {
      this.translations[language] = {
        name: this.translatedCategories[language].Name,
        userUpdated: this.translatedCategories[language].userUpdated
      };
    })

    if (this.translatedCategories['en'].Name.length > 5000) {
      this.message =
        'Name must be less than 5000 characters';
      this.error = true;
    } else if (newCategory.Name === undefined || newCategory.Name === '') {
      this.errorMessage = 'Name is required';
      this.error = true;
    } else {
      this.categoryAccessor
        .getUpdatableCategories()
        .push(newCategory)
        .then((snap) => {
          this.translateService.translateCategory(newCategory, snap.key, this.translations);
          this.errorMessage = '';
          this.error = false;
          this.adding = false;
        });
      this.message = 'Success';
    }
  }

  hasDependent(value: string): boolean {
    let result = false;
    this.programs.forEach((program) => {
      if (program['CategoryId'] === value) {
        result = true;
      }
    });
    return result;
  }

  delete(value: string) {
    if (this.hasDependent(value)) {
      this.message = 'Category cannot be deleted if in use';
    } else {
      if (confirm('Are you sure you want to delete this category?')) {
        this.message = 'Success';
        this.categoryAccessor.getUpdatableCategories().remove(value);
        this.translateService.removeCategory(value);
        this.categories = this.categoryAccessor.getCachedCategories();
      }
    }
  }

  cancel() {
    this.adding = false;
    this.errorMessage = '';
    this.error = false;
  }
}
