import { Component, OnInit } from '@angular/core';
import { Organization } from '../organizations/organization';
import { Program } from '../Models/program.model';
import * as jsPDF from 'jspdf';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';
import { CategoryService } from 'app/Services/category.service';
import { SubcategoryService } from 'app/Services/subcategory.service';
import { PreferencesService } from 'app/Services/preferences.service';
@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css'],
})
export class PDFComponent implements OnInit {
  organizations: Organization[];
  programs: Program[];
  isDownloading: boolean = false;
  selectedLanguage: string = 'en';
  entityName: string;

  constructor(
    private programAccessor: ProgramService,
    private organizationAccessor: OrganizationService,
    private categoryAccessor: CategoryService,
    private subCategoryAccessor: SubcategoryService,
    private preferencesAccessor: PreferencesService
  ) { }

  ngOnInit() {
    this.organizations = this.organizationAccessor.getCachedOrganizations();
    this.programs = this.programAccessor.getCachedPrograms();
    this.entityName = this.preferencesAccessor.getLocationCode();
  }

  public genPDF(type: string, bodySize: number) {
    let docHeight;
    let docWidth;
    switch (type) {
      case 'letter':
        docHeight = 792;
        docWidth = 612;
        break;
      case 'a4':
        docHeight = 840;
        docWidth = 595;
        break;
      case 'legal':
        docHeight = 997;
        docWidth = 612;
        break;
    }
    //variable for font size. actual font itself should matter, just the size.
    const whiteSpace: number = bodySize / 2; //space between lines in pixels. important for calculations so dont modify
    let doc = new jsPDF('p', 'px', type); //generates portrait pdf with pixel standard and selected aspect ratio.
    //Below are 2 arrays that store the sections of html code and their correspondeing heights.
    let docOut: string[] = [
      '<h1 style="font-size:30px;line-height:' +
      whiteSpace +
      'px">MyLink - Services in the Lincoln/Lancaster county area<br></h1>',
    ]; //first item is header
    let lineHeight: number[] = [(30 + whiteSpace) * 2]; //sets first value as height of the header.
    const totalHeight = Number(bodySize) + whiteSpace; //you have to explicitly cast bodysize to a number here or it concatenates for some reason.
    let programNum = 1; //keeps track of number of programs
    this.organizations.forEach((organization) => {
      //creates organization block
      docOut.push(
        '<div style="font-size:' +
        bodySize +
        'px;line-height:' +
        whiteSpace +
        'px"><p style="font-weight: bold;">' +
        programNum++ +
        '. ' +
        organization.Name.replace(/[^\x20-\x7E]+/g, '') +
        '</p><p> Organization website: ' +
        organization.Website +
        ' Phone number: ' +
        organization.Call +
        '</p></div>'
      );
      lineHeight.push(totalHeight * 2);
      let ifPrograms = false;
      this.programs.forEach((program) => {
        //checks for programs of an organization
        if (
          program.Summary != '' &&
          program.Summary != undefined &&
          organization.key == program.OrganizationId
        ) {
          if (!ifPrograms) {
            docOut.push(
              '<p style="font-size:' +
              bodySize +
              'px;line-height:' +
              whiteSpace +
              'px">Related Programs:</p>'
            );
            lineHeight.push(totalHeight);
            ifPrograms = true;
          }
          let addLine = 1;
          //create parts for each listing. jsPDF has limited character support, so the program removes invalid characters.
          let add = 'Address:' + program.Address.replace(/[^\x20-\x7E]+/g, '');
          if (add == 'Address:') {
            //if there is no address, makes it not display.
            add = '';
            addLine = 0;
          }
          let summary = doc.splitTextToSize(
            '<span style="font-weight: bold;">Program Name: </span>' +
            program.Summary.replace(/[^\x20-\x7E]+/g, ''),
            docWidth - 10
          ); //wraps summary to document width
          //truncates components into one chunk of code.0
          let tempstring = '';
          summary.forEach((text) => {
            tempstring += '<p style="margin-left: 40px;">' + text + '</p>';
            addLine++;
          });
          tempstring += '<p style="margin-left: 40px;">' + add + '</p>';
          //pushes code with div styling
          docOut.push(
            '<div style="font-size:' +
            bodySize +
            'px;line-height:' +
            whiteSpace +
            'px">' +
            tempstring +
            '</div>'
          );
          //calculates the height of lines added
          lineHeight.push(totalHeight * addLine);
        }
      });
    });

    let tempString = '';
    let tempHeight = 0;
    let pgCheck = 0;
    for (let i = 0; i < docOut.length; i++) {
      //checks to see if current addition would surpass document height, finishes current page and makes a new one if so
      if (tempHeight + lineHeight[i] >= docHeight) {
        doc.fromHTML(tempString, /*left margin ->*/ 25, {}, function () { });
        doc.addPage();
        tempString = docOut[i];
        tempHeight = lineHeight[i];
      } else {
        tempString += docOut[i];
        tempHeight += lineHeight[i];
      }
    }
    doc.fromHTML(tempString, 25, {}, function () { }); //adds last page
    //downloads PDF
    doc.save('MyLink.pdf');
  }

  public exportOrganizations() {
    let csvContent =
      'data:text/csv;charset=utf-8,ORGANIZATION NAME,PHONE NUMBER,EMAIL ADDRESS,WEBSITE\r\n';

    this.organizationAccessor.getOrganizations().subscribe((organizations) => {
      if (organizations.length) {
        organizations.forEach((organization) => {
          let row = [
            organization.Name
              ? organization.Name.replace(',', '').trim()
              : 'name not found',
            organization.Call ? organization.Call.trim() : '',
            organization.Email ? organization.Email.trim() : '',
            organization.Website ? organization.Website.trim() : '',
          ].join(',');
          csvContent += row + '\r\n';
        });
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'MyLink_OrganizationList.csv');
        document.body.appendChild(link);
        link.click();
      }
    });
  }

 async exportPrograms() {
    this.isDownloading =  true;
    let csvContent =
      'PROGRAM ID,CATEGORY NAME,SUBCATEGORY NAME,ORGANIZATION NAME,PROGRAM NAME,PROGRAM WEBSITE,PROGRAM PHONE NUMBER,PROGRAM EMAIL,PROGRAM ADDRESS,PROGRAM LOCATION LATITUDE,PROGRAM LOCATION LONGITUDE,PROGRAM DESCRIPTION\r\n';
    const programs = await this.programAccessor.findPrograms(this.selectedLanguage);

    for (let program of programs) {
      let organizationName: string = 'N/A';
      let categoryName: string = 'N/A';
      let subCategoryName: string = 'N/A';

      if(program.OrganizationId){
        const organization = await this.organizationAccessor.findOrganization(program.OrganizationId);
        if(organization && organization.Name){
          organizationName = organization.Name.replace(/,/g, '');
        }          
      }
      if(program.CategoryId){
        const category = await this.categoryAccessor.findCategory(program.CategoryId);
        if(category && category.Name){
          categoryName = category.Name.replace(/,/g, '');
        }
        if(program.SubcategoryId){
          const subCategory = await this.subCategoryAccessor.findSubcategory(program.CategoryId, program.SubcategoryId);
          if(subCategory && subCategory.Name){
            subCategoryName = subCategory.Name.replace(/,/g, '');
          }
        }
      }

      let row = [
        program.key ? ' ' + program.key : 'N/A', // Added a space in front of program key since some of them start with a dash. In this way Excel formats it correctly. 
        categoryName,
        subCategoryName,
        organizationName,
        program.Summary ? program.Summary.replace(/,|\n/g, '').trim() : 'N/A',
        program.Website ? program.Website.replace(/,|\n/g, '').trim() : 'N/A',
        program.Call ? program.Call.replace(/,|\n/g, '').trim() : 'N/A',
        program.Email ? program.Email.trim() : 'N/A',
        program.Address ? program.Address.replace(/,|\n/g, '').trim() : 'N/A',
        program.Coordinates ? program.Coordinates.lat : 'N/A',
        program.Coordinates ? program.Coordinates.lng : 'N/A',
        program.Description ? program.Description.replace(/,|\n/g, '') : 'N/A',
      ].join(',');
      csvContent += row + '\r\n';
    };

    let encodedUri = encodeURIComponent(csvContent);
    let link = document.createElement('a');
    const fileName = `MyLink_${this.entityName}_${this.selectedLanguage}_ProgramList.csv`;
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    link.click();

    this.isDownloading = false;
  }
}
