import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireList } from 'angularfire2/database';
import { Category } from './category';
import { TranslateService } from '../firebase/translate.service';
import { UserService } from '../Services/user.service';
import { PreferencesService } from 'app/Services/preferences.service';
import { Language } from 'app/Models/program.model';
import { CategoryService } from 'app/Services/category.service';

@Component({
  selector: 'edit-category',
  templateUrl: './editCategory.component.html',
  styleUrls: ['./editCategory.component.css'],
})
export class EditCategoryComponent implements OnInit {
  id: string;
  path: string;
  locationCode: string;

  categories: AngularFireList<any>;

  categoryName: string;
  categoryIcon: string;

  translations: Language[] = [];
  translatedCategories = {};
  languages: string[];
  message: string;
  error = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private userAccessor: UserService,
    private ps: PreferencesService,
    private categoryAccessor: CategoryService
  ) {}

  updateAll() {
    this.categoryIcon = this.categoryIcon || ''

    this.languages.forEach((language) => {
      this.translations[language] = {
        name: this.translatedCategories[language].Name,
        userUpdated: this.translatedCategories[language].userUpdated,
      };
    });

    const updatedCategory: Category = {
      Name: this.translatedCategories['en'].Name.trim(),
      Icon: this.categoryIcon.trim(),
    }

    const updatableCategories = this.categoryAccessor.getUpdatableCategories();
    updatableCategories.update(this.id, updatedCategory);
    this.translate.translateCategory(
      updatedCategory,
      this.id,
      this.translations
    )
  }

  async ngOnInit() {
    this.languages = await this.translate.parseLanguages();
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.locationCode = this.ps.getLocationCode();

    this.path = `entities/${this.locationCode}/Data/en/Categories/${this.id}/Subcategories`;

    this.categories = this.userAccessor.findOne(
      `entities/${this.locationCode}/Data/en/Categories/`,
      this.id
    );

    this.categories.valueChanges().subscribe((value) => {
      this.categoryName = value[0].Name;
      this.categoryIcon = value[0].Icon;
    });

    for (const language of this.languages) {
      this.translatedCategories[
        language
      ] = await this.categoryAccessor.getSingleCategory(this.id, language);
      if (!this.translatedCategories[language]) {
        // If program doesn't exist in language, set display values for edit page
        this.translatedCategories[language] = {
          Name: '',
          userUpdated: false,
        };
      }
    }
  }
}
