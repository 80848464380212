import { Timestamp } from '../programs/timestamp';

export class ScheduledEvent {
  Cycle: string;
  Day: string;
  Id: string;
  Time: string;
  Timestamp: Timestamp;
  StartTime: string;
  EndTime: string;
  name?: string;
  key?: string;
}

export const CycleList: string[] = ['Daily', 'Weekly', 'Monthly'];

export const DayList: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'First Monday',
  'Second Monday',
  'Third Monday',
  'Fourth Monday',
  'Fifth Monday',
  'First Tuesday',
  'Second Tuesday',
  'Third Tuesday',
  'Fourth Tuesday',
  'Fifth Tuesday',
  'First Wednesday',
  'Second Wednesday',
  'Third Wednesday',
  'Fourth Wednesday',
  'Fifth Wednesday',
  'First Thursday',
  'Second Thursday',
  'Third Thursday',
  'Fourth Thursday',
  'Fifth Thursday',
  'First Friday',
  'Second Friday',
  'Third Friday',
  'Fourth Friday',
  'Fifth Friday',
  'First Saturday',
  'Second Saturday',
  'Third Saturday',
  'Fourth Saturday',
  'Fifth Saturday',
  'First Sunday',
  'Second Sunday',
  'Third Sunday',
  'Fourth Sunday',
  'Fifth Sunday',
  'All',
];
