import { Component, OnInit, ViewChild } from '@angular/core';
import { Program, Language } from '../Models/program.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Organization } from '../organizations/organization';
import { OrganizationService } from '../Services/organization.service';
import { ProgramService } from '../Services/program.service';
import { TranslateService } from '../firebase/translate.service';
import { ScheduleService } from '../Services/schedule.service';
import { Category, Subcategory } from '../categories/category';
import { CategoryService } from '../Services/category.service';
import { SubcategoryService } from '../Services/subcategory.service';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { GeocodeService } from '../Services/geocode.service';
import { Coordinate } from '../Models/coordinate.model';

@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.css'],
})
export class ProgramListComponent implements OnInit {
  hasMissingCoordinates: boolean;
  categories: Category[] = [];
  subcategories: Subcategory[] = [];
  organizations: Organization[];
  programsArray: Program[] = [];
  newProgram: Program = new Program();
  message: string;
  adding = false;
  errorMessage: string;
  error = false;
  translations: Language[] = [];
  languages: string[];

  displayedColumns = ['Name', 'Organization', 'LastUpdated', 'Delete'];
  dataSource: MatTableDataSource<Program>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private organizationAccessor: OrganizationService,
    private categoryAccessor: CategoryService,
    private subcategoryAccessor: SubcategoryService,
    private programAccessor: ProgramService,
    private translationAccessor: TranslateService,
    private scheduleAccessor: ScheduleService,
    public auth: FirebaseAuthService,
    private geocoder: GeocodeService
  ) {}

  async ngOnInit() {
    this.languages = await this.translationAccessor.parseLanguages();
    this.organizations = await this.organizationAccessor.findOrganizations('en');
    this.categories = await this.categoryAccessor.findCategories('en');

    this.fetchProgramListData();

    this.languages.forEach((language) => {
      this.translations[language] = {
        summary: '',
        description: '',
        userUpdated: false,
      };
    });
  }

  fetchProgramListData() {
    this.programAccessor
      .getUpdatablePrograms()
      .snapshotChanges()
      .subscribe((programs) => {
        this.programsArray = [];
        programs.forEach((program) => {
          let p: Program = program.payload.val();
          p.key = program.key;
          this.programsArray.push(p);
        });
        this.dataSource = new MatTableDataSource(
          this.programsArray.sort((a, b) => {
            const textA = this.getDisplayName(a, 5);
            const textB = this.getDisplayName(b, 5);
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setSortingAccessor();

        this.hasMissingCoordinates = this.programsArray.filter(p => p.Address && !p.Coordinates).length > 0;
      });
  }

  setSortingAccessor() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Name': {
          return this.getDisplayName(item, 5);
        }
        case 'Organization': {
          return this.getOrganization(item);
        }
        case 'LastUpdated': {
          return item.Timestamp ? item.Timestamp.Time : 1420095600000;
        }
      }
    };
    this.sort.direction = 'asc';
    this.sort.active = 'Name';
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addingProgram() {
    this.adding = !this.adding;
    this.newProgram.Summary = '';
    this.newProgram.OrganizationId = '';
    this.translationAccessor.languages.forEach((language) => {
      this.translations[language] = {
        summary: '',
        description: '',
        userUpdated: false,
      };
    });
  }

  cancel() {
    this.adding = false;
    this.errorMessage = '';
    this.error = false;
  }

  add() {
    const updatablePrograms = this.programAccessor.getUpdatablePrograms();
    this.newProgram.Timestamp = { Time: 0, Changes: '', Email: '' };
    this.newProgram.Timestamp.Time = Date.now();
    this.newProgram.Timestamp.Email = this.auth.user.email;

    // clean up whitespace
    this.newProgram.Summary = this.translations['en'].summary.trim();
    this.newProgram.Description = this.translations['en'].description.trim();
    this.newProgram.Call = this.newProgram.Call.trim();
    this.newProgram.Email = this.newProgram.Email.trim();
    this.newProgram.Website = this.formatUrl(this.newProgram.Website.trim());

    const newProgram = this.newProgram;

    if (newProgram.Summary === undefined || newProgram.Summary === '') {
      this.message = 'Program Name is required in English';
      this.error = true;
    } else if (newProgram.Summary.length > 5000) {
      this.message = 'Name must be fewer than 5000 characters';
      this.error = true;
    } else if (
      newProgram.OrganizationId === null ||
      newProgram.OrganizationId === ''
    ) {
      this.message = 'Invalid organization. Choose existing organization';
      this.error = true;
    } else if (newProgram.CategoryId === null || newProgram.CategoryId === '') {
      this.message = 'Invalid category. Choose existing category';
      this.error = true;
    } else if (newProgram.Description.length > 5000) {
      this.message = 'Description must have fewer than 5000 characters';
      this.error = true;
    } else {
      let key;
      updatablePrograms.push(newProgram).then((snap) => {
        key = snap.key;
        this.translationAccessor.translateProgramsByUserUpdated(
          newProgram,
          key,
          this.translations
        );
      });
      this.message = 'Success!';
      this.adding = false;

      if (newProgram.Address) {
        this.geocoder
          .geocodeNebraskaAddress(this.newProgram.Address)
          .subscribe((location: Coordinate) => {
            updatablePrograms.update(key, { Coordinates: location });
            this.translationAccessor.addCoordinates(key, location);
          });
      }
    }
    this.error = false;
  }

  addMissingCoordinates = () =>  {
    const updatablePrograms = this.programAccessor.getUpdatablePrograms();

    if (confirm('This could take a while, do you want to proceed?')) {
      this.programsArray.forEach((p: Program) => {
        if (!p.Coordinates && p.Address) {
          this.geocoder
            .geocodeAddress(p.Address)
            .subscribe((location: Coordinate) => {
              updatablePrograms.update(p.key, { Coordinates: location });
              this.translationAccessor.addCoordinates(p.key, location);
            });
        }
      });
    }
  }

  formatUrl(url: string) {
    if (!url.startsWith('http') && url.length > 0) {
      url = 'http://' + url;
    }
    return url;
  }

  goToProgram(row) {
    this.router.navigate([`/home/program/${row.key}`]);
  }

  getDisplayName(program: Program, maxLength: number): string {
    if (program.Summary) {
      return this.caps(program.Summary.trim());
    }
    if (program.Description) {
      return program.Description.length > maxLength
        ? this.caps(program.Description.substring(0, maxLength + 1).trim()) +
            '...'
        : this.caps(program.Description.trim());
    }
    return '(none)';
  }

  caps(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getOrganization(program: Program) {
    const foundOrganization = this.organizations.find(
      (x) => x.key === program.OrganizationId
    );
    if (!foundOrganization) {
      return null;
    }
    return foundOrganization.Name;
  }

  delete(program: Program) {
    if (
      confirm('Are you sure you want to delete this program from the database?')
    ) {
      this.message = 'Successfully deleted program';
      this.programAccessor.getUpdatablePrograms().remove(program.key);
      this.scheduleAccessor.getUpdatableEvents().remove(program.key);
      this.translationAccessor.removeProgram(program.key);
      this.programsArray = [];
    }
  }

  getOrganizationKey(e) {
    const organization = this.organizations.find(
      (x) => x.Name === e.target.value
    );
    if (organization) {
      this.newProgram.OrganizationId = organization.key;
    }
  }

  getCategoryKey(e) {
    const category = this.categories.find((x) => x.Name === e.target.value);
    if (category) {
      this.newProgram.CategoryId = category.key;
    }

    // change subcategory choices based off which category is chosen
    this.subcategories = [];
    this.subcategoryAccessor
      .getUpdatableSubcategories(this.newProgram.CategoryId)
      .snapshotChanges()
      .subscribe((subcategories) => {
        subcategories.forEach((subcategory) => {
          const s: Subcategory = subcategory.payload.val();
          s.key = subcategory.key;
          this.subcategories.push(s);
        });
      });
  }

  getSubCategoryKey(e) {
    const subcategory = this.subcategories.find(
      (x) => x.Name === e.target.value
    );
    if (subcategory) {
      this.newProgram.SubcategoryId = subcategory.key;
    }
  }
}
