<div class="login">
  <h3>Login</h3>
  <h5 class="alert">{{ auth.badMessage }}</h5>
  <input
    #loginEmail
    type="email"
    placeholder="Email"
    (keyup.enter)="loginUser(loginEmail.value, loginPassword.value)"
  />
  <br />
  <input
    #loginPassword
    type="password"
    placeholder="Password"
    (keyup.enter)="loginUser(loginEmail.value, loginPassword.value)"
  />
  <br />
  <button (click)="loginUser(loginEmail.value, loginPassword.value)">
    Login
  </button>
</div>
