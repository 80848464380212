import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ObjectCache } from '../Models/objectCache.model';
import { Observable } from 'rxjs';
import { Category } from '../categories/category';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends DataService<Category> {
  constructor(private db: AngularFireDatabase, private ps: PreferencesService) {
    super(db, `entities/${ps.getLocationCode()}/Data/en/Categories`, new ObjectCache<Category>());
  }

  getCachedCategories(lang?: string): Category[] {
    return super.getCachedObjects(lang);
  }

  getCategoryById(id: string, lang?: string): Observable<Category> {
    return super.getObjectById(id, lang);
  }

  getUpdatableCategories(lang?: string): AngularFireList<Category> {
    return super.getAngularFireObjects(lang);
  }

  async getSingleCategory(id: string, lang?: string): Promise<Category> {
    let path = this.PATH + '/' + id;
    if (lang) {
      path = path.replace('/en/', `/${lang}/`);
    }
    const result = await super.getRawDataFromPath(path);
    let results: Category;
    results = result;
    return results;
  }

  async findCategory(id: string, lang?: string): Promise<Category> {
    return await super.findDocument(id, lang);
  }

  async findCategories(lang?: string): Promise<Category[]> {
    return await super.findCollection(lang);
  }
}
