import { Injectable } from '@angular/core';
import { Program, Language } from '../Models/program.model';
import { Organization } from '../organizations/organization';
import { Category, Subcategory } from '../categories/category';
import { OrganizationService } from '../Services/organization.service';
import { ProgramService } from '../Services/program.service';
import { CategoryService } from '../Services/category.service';
import { SubcategoryService } from '../Services/subcategory.service';
import { Coordinate } from 'app/Models/coordinate.model';
import { environment } from '../../environments/environment';
import { DataService } from '../Services/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TranslateService {
  languages: string[] = [];
  translationLimitMessage = 'Error: Daily translation limit reached. ' +
    'Please wait until tomorrow to translate more organizations, ' +
    'or contact an administrator if you think this is an error.'

  endpoint: string = 'https://api-nam.cognitive.microsofttranslator.com/translate?api-version=3.0';

  constructor(
    private httpClient: HttpClient,
    private organizationAccessor: OrganizationService,
    private programAccessor: ProgramService,
    private categoryAccessor: CategoryService,
    private subcategoryAccessor: SubcategoryService
  ) { }

  async findLanguages(): Promise<any> {
    const ref = DataService.dbRef.ref('/languages');
    return (await ref.once('value')).val();
  }

  async parseLanguages(): Promise<string[]> {
    if (!this.languages.length) {
      const result = await this.findLanguages();
      for (const i of Object.keys(result)) {
        this.languages.push(i);
      }
    }
    return this.languages;
  }

  static scrubName(name: string): string {
    if (name) {
      return name.replace('&', 'and').replace(/[\W_]+/g, ' ');
    } else {
      return '';
    }
  }

  static getHttpOptions(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': environment.translateKey,
      'Ocp-Apim-Subscription-Region': 'centralus'
    });
  }

  translateOrganization(
    organization: Organization,
    id: string,
    updated: boolean
  ) {
    this.languages.forEach((language) => {
      this.organizationAccessor
        .getOrganizationById(id, language)
        .subscribe((snapshot: Organization) => {
          if (snapshot != null && !updated) {
            return;
          }
          try {
            let body = [{ "text": TranslateService.scrubName(organization.Name) }];
            let httpOptions = {
              headers: TranslateService.getHttpOptions()
            };
            this.httpClient
              .post(`${this.endpoint}&to=${language}`, body, httpOptions)
              .subscribe((response) => {
                this.organizationAccessor
                  .getUpdatableOrganizations(language)
                  .set(id, {
                    Address: organization.Address || null,
                    Call: organization.Call || null,
                    Email: organization.Email || null,
                    Image: organization.Image || null,
                    Name: (response as any)[0].translations[0].text,
                    Website: organization.Website || null,
                  });
              });
          } catch (e) {
            alert(
              this.translationLimitMessage
            );
          }
        });
    });
  }

  translateOrganizationByUserUpdated(organization: Organization, id: string, translations: Language[]) {
    {
      this.languages.forEach((language) => {
        if (translations[language].userUpdated) {
          const updatableOrganizations = this.organizationAccessor.getUpdatableOrganizations(language)
          updatableOrganizations.set(id, {
            Address: organization.Address.trim() || null,
            Call: organization.Call.trim() || null,
            Name: translations[language].name || null,
            Email: organization.Email.trim() || null,
            Website: organization.Website.trim() || null,
            userUpdated: translations[language].userUpdated || null,
          });
        } else if (language !== 'en') {
          try {
            let body = [{ "text": TranslateService.scrubName(translations['en'].name)}];
            let httpOptions = {
              headers: TranslateService.getHttpOptions()
            };
            this.httpClient
              .post(`${this.endpoint}&to=${language}`, body, httpOptions)
              .subscribe((response) => {
                const responseJson = (response as any);
                translations[language].name = responseJson[0].translations[0].text;
                const updatableOrganizations = this.organizationAccessor.getUpdatableOrganizations(language);
                updatableOrganizations.set(id, {
                  Address: organization.Address.trim() || null,
                  Call: organization.Call.trim() || null,
                  Name: translations[language].name || null,
                  Email: organization.Email.trim() || null,
                  Website: organization.Website.trim() || null,
                  userUpdated: translations[language].userUpdated || null,
                });
              });
          } catch (e) {
            alert(
              'Error: Daily translation limit reached. Please wait until tomorrow to translate more programs, or contact an administrator if you think this is an error.'
            );
          }
        }
      });
    }
  }

  removeOrganization(id: string) {
    this.languages.forEach((language) => {
      this.organizationAccessor.getUpdatableOrganizations(language).remove(id);
    });
  }

  addCoordinates(id: string, coordinates: Coordinate) {
    this.languages.forEach((language) => {
      if (coordinates) {
        const updatablePrograms = this.programAccessor.getUpdatablePrograms(
          language
        );
        updatablePrograms.update(id, { Coordinates: coordinates });
      }
    });
  }

  translateProgramsByUserUpdated(
    program: Program,
    id: string,
    translations: Language[]
  ) {
    this.languages.forEach((language) => {
      if (translations[language].userUpdated) {
        const updatablePrograms = this.programAccessor.getUpdatablePrograms(
          language
        );
        updatablePrograms.set(id, {
          Address: program.Address.trim() || null,
          Call: program.Call.trim() || null,
          CategoryId: program.CategoryId.trim(),
          Description: translations[language].description || null,
          Email: program.Email.trim() || null,
          OrganizationId: program.OrganizationId,
          SubcategoryId: program.SubcategoryId || null,
          SubcategoryIds: program.SubcategoryIds || [
            program.SubcategoryId || null,
          ],
          Summary: translations[language].summary || null,
          Website: program.Website.trim() || null,
          Timestamp: program.Timestamp,
          userUpdated: translations[language].userUpdated || null,
        });
      } else {
        if (language !== 'en') {
          try {
            let body = [
              { "text": TranslateService.scrubName(translations['en'].summary) },
              { "text": TranslateService.scrubName(translations['en'].description) }
            ]
            let httpOptions = {
              headers: TranslateService.getHttpOptions()
            }
            this.httpClient
              .post(`${this.endpoint}&to=${language}`, body, httpOptions)
              .subscribe((response) => {
                const responseJson = (response as any);
                translations[language].summary =
                  responseJson[0].translations[0].text;
                translations[language].description =
                  responseJson[1].translations[0].text;
                const updatablePrograms = this.programAccessor.getUpdatablePrograms(
                  language
                );
                updatablePrograms.set(id, {
                  Address: program.Address.trim() || null,
                  Call: program.Call.trim() || null,
                  CategoryId: program.CategoryId.trim(),
                  Description: translations[language].description || null,
                  Email: program.Email.trim() || null,
                  OrganizationId: program.OrganizationId,
                  SubcategoryId: program.SubcategoryId || null,
                  SubcategoryIds: program.SubcategoryIds || [
                    program.SubcategoryId || null,
                  ],
                  Summary: translations[language].summary || null,
                  Website: program.Website.trim() || null,
                  Timestamp: program.Timestamp,
                  userUpdated: translations[language].userUpdated || null,
                });
              });
          } catch (e) {
            alert(
              this.translationLimitMessage
            );
          }
        }

      }
    });
  }

  removeProgram(id: string) {
    this.languages.forEach((language) => {
      const updatablePrograms = this.programAccessor.getUpdatablePrograms(
        language
      );
      updatablePrograms.remove(id);
    });
  }

  translateSubCategory(
    subcategory: Subcategory,
    updated: boolean,
    categoryId: string,
    subcategoryId: string
  ) {
    const queries = TranslateService.scrubName(subcategory.Name);

    this.languages.forEach(async (language) => {
      if (language !== 'en') {
        const translatedName = await this.callGoogleTranslateString(language, queries);
        if (translatedName) {
          this.subcategoryAccessor
            .getUpdatableSubcategories(categoryId, language)
            .set(subcategoryId, {
              Name: translatedName,
              userUpdated: updated || null
            });
        }
      }
    });
  }

  removeSubcat(subcategoryId: string, categoryId: string) {
    this.languages.forEach((language) => {
      this.subcategoryAccessor
        .getUpdatableSubcategories(categoryId, language)
        .remove(subcategoryId);
    });
  }

  translateCategory(category: Category, id: string, translations?: Language[]) {
    const queries = TranslateService.scrubName(category.Name);
    if (!translations) {
      this.languages.forEach(async (language) => {
        if (language !== 'en') {
          const translatedName = await this.callGoogleTranslateString(language, queries);
          if (translatedName) {
            this.updateCategory(language, id, translatedName, category, false);
          }
        }
      });
    } else {
      this.languages.forEach(async (language) => {
        if (translations[language].userUpdated) {
          this.updateCategory(language, id, translations[language].name, category, translations[language].userUpdated);
        } else if (language !== 'en') {
          const translatedName = await this.callGoogleTranslateString(language, queries);
          if (translatedName) {
            this.updateCategory(language, id, translatedName, category, translations[language].userUpdated);
          }
        }
      });
    }
  }

  private updateCategory(language: string, id: string, name: string, category: Category, userUpdated: boolean) {
    const updatableCategories = this.categoryAccessor.getUpdatableCategories(language);

    updatableCategories.update(id, {
      Name: name || null,
      Icon: category.Icon || null,
      userUpdated: userUpdated || null
    })
  }

  removeCategory(id: string) {
    this.languages.forEach((language) => {
      this.categoryAccessor.getUpdatableCategories(language).remove(id);
    });
  }

  async translateString(language: string, text: string): Promise<string> {
    const queries = TranslateService.scrubName(text);
    var responseTranslation = await this.callGoogleTranslateString(language, queries);
    if (responseTranslation) {
      return responseTranslation;
    } else {
      return "";
    }
  }

  private async callGoogleTranslateString(language: string, queries: string): Promise<string> {
    let body = [
      { "text": queries }
    ]
    let httpOptions = {
      headers: TranslateService.getHttpOptions()
    }
    const response: any = await this.httpClient.post(`${this.endpoint}&to=${language}`, body, httpOptions).toPromise();
    var text = response[0].translations[0].text;
    return text;
  }
}
