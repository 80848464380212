import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ObjectCache } from '../Models/objectCache.model';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/database';
import { ScheduledEvent } from '../scheduled/scheduledEvent';
import { Observable } from 'rxjs';
import { PreferencesService } from './preferences.service';

class Schedule { }

@Injectable({
  providedIn: 'root',
})
export class ScheduleService extends DataService<ScheduledEvent> {
  eventsStandardList: ScheduledEvent[] = [];

  constructor(private db: AngularFireDatabase, private ps: PreferencesService) {
    super(db, `entities/${ps.getLocationCode()}/Data/Scheduled`, new ObjectCache<ScheduledEvent>());
  }

  getScheduleById(id: string, lang?: string): Observable<ScheduledEvent> {
    return super.getObjectById(id, lang);
  }

  getUpdatableEvents(lang?: string): AngularFireList<ScheduledEvent> {
    return super.getAngularFireObjects(lang);
  }

  getAllEvents() {
    return this.eventsStandardList;
  }

  findSingleEvent(id: string) {
    let items: AngularFireList<ScheduledEvent>;
    const events: ScheduledEvent[] = [];
    items = this.db.list('/3/Scheduled', (ref) => ref.orderByKey().equalTo(id));
    items.valueChanges().subscribe((snapshots) => {
      snapshots.forEach((snapshot) => {
        const event: ScheduledEvent = snapshot;
        event['key'] = snapshot.key;
        events.push(event);
      });
    });
  }

  getSchedulesByProgram(eventId: string) {
    const schedules: ScheduledEvent[] = [];

    this.db
      .list<ScheduledEvent>('3/Scheduled/')
      .valueChanges()
      .subscribe((snapshots) => {
        snapshots.forEach((snapshot) => {
          if (snapshot.Id === eventId) {
            schedules.push(snapshot);
          }
        });
      });
    return schedules;
  }
}
