<h3>Missing Data</h3>
<div id="radios">
  <input type="radio" name="list" value="organizations" [(ngModel)]="selected"
    (change)="changed()" />Organizations<br />
  <input type="radio" name="list" value="programs" [(ngModel)]="selected" (change)="changed()" />Programs<br />
  <input type="radio" name="list" value="events" [(ngModel)]="selected" (change)="changed()" />Events
</div>
<div class="table">
  <table *ngIf="orgSelected">
    <Label class="padding">Filter by:</Label>
    <button class="big-button" (click)="filterList('Name')">Name</button>
    <button class="big-button" (click)="filterList('Address')">Address</button>
    <button class="big-button" (click)="filterList('Call')">
      Phone Number
    </button>
    <button class="big-button" (click)="filterList('Email')">Email</button>
    <button class="big-button" (click)="filterList('Website')">Website</button>
    <p class="length">{{ missingOrgs.length }} Matching Items</p>
    <tr>
      <th>Organizations</th>
    </tr>
    <tr *ngFor="let org of missingOrgs">
      <td (click)="orgGoTo(org.key)">
        <span *ngIf="org.Name != ''">{{ org.Name }}</span><span *ngIf="org.Name === ''">{{ org.key }}</span>
      </td>
    </tr>
  </table>
  <table *ngIf="programSelected">
    <Label class="padding">Filter by:</Label>
    <button class="big-button" (click)="filterList('Summary')">Program Name</button>
    <button class="big-button" (click)="filterList('Description')">
      Description
    </button>
    <button class="big-button" (click)="filterList('Address')">Address</button>
    <button class="big-button" (click)="filterList('Call')">
      Phone Number
    </button>
    <button class="big-button" (click)="filterList('Email')">Email</button>
    <button class="big-button" (click)="filterList('Website')">Website</button>
    <button class="big-button" (click)="filterList('CategoryId')">
      Category
    </button>
    <button class="big-button" (click)="filterList('SubcategoryId')">
      Subcategory
    </button>
    <button class="big-button" (click)="filterList('OrganizationId')">
      Organization
    </button>
    <p class="length">{{ missingPrograms.length }} Matching Items</p>
    <tr>
      <th>Programs</th>
    </tr>
    <tr *ngFor="let program of missingPrograms">
      <td (click)="goto(program.CategoryId, program.key)">
        <span *ngIf="program.Summary != ''">{{ program.Summary }}</span><span
          *ngIf="program.Summary === ''">{{ program.key }}</span>
      </td>
    </tr>
  </table>
  <table *ngIf="eventSelected">
    <Label class="padding">Filter by:</Label>
    <button class="big-button" (click)="filterList('Cycle')">Cycle</button>
    <button class="big-button" (click)="filterList('Day')">Day</button>
    <button class="big-button" (click)="filterList('Time')">Time</button>
    <p class="length">{{ missingEvents.length }} Matching Items</p>
    <tr>
      <th>Events</th>
    </tr>
    <tr *ngFor="let event of missingEvents">
      <td (click)="eventGoTo(event.Id)">
        <span *ngIf="event.name != ''">{{ event.name }}</span><span *ngIf="event.name === ''">{{ event.key }}</span>
      </td>
    </tr>
  </table>
</div>