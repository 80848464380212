export class Category {
  Name: string;
  Icon?: string;
  key?: string;
  Subcategories?: Subcategory[];
  userUpdated?: boolean;
}

export class Subcategory {
  Name: string;
  key?: string;
  userUpdated?: boolean;
}
