<div>
  <div class="search">
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="Search"
      />
    </mat-form-field>
  </div>
  <h3 class="header">
    Organizations <a class="sub add" (click)="addingOrg()">Add Organization </a>
  </h3>
  <div class="update" *ngIf="adding">
    <h4>Add Organization</h4>
    <h5>* denotes a required field. Program name is required in english, but can be specified in additional languages </h5>
    <div>
      <p class="alert">{{ errorMessage }}</p>

      <label>English</label>
      <div class="textbox">
        <label>Organization Name*</label>
      </div>
      <div>
      </div>
      <div class="textbox">
        <input
          [ngClass]="{ error: error }"
          [(ngModel)]="newOrganization.Name"
        />
      </div>
      <div *ngFor="let language of languages">
        <div *ngIf="language !== 'en' && translatedOrganization[language]">
          <dt>
            <label>{{language}}:</label>
          </dt>
          <dd>
            <input class="checkbox" type="checkbox" [checked]="!translatedOrganization[language]?.userUpdated"
              (change)="translatedOrganization[language].userUpdated = !translatedOrganization[language]?.userUpdated" />
            Automatically Update
          </dd>
          <dd>
            <label>Organization Name</label>
          </dd>
          <dd>
            <input type="text" [ngClass]="{ error: error }" [(ngModel)]="translatedOrganization[language].Name" />
          </dd>
        </div>
      </div>      
      <div>
        <label>Address</label>
      </div>
      <div>
        <input
          [ngClass]="{ error: error }"
          [(ngModel)]="newOrganization.Address"
        />
      </div>

      <div>
        <label>Phone Number</label>
      </div>
      <div>
        <input
          [ngClass]="{ error: error }"
          [(ngModel)]="newOrganization.Call"
        />
      </div>

      <div>
        <label>Website</label>
      </div>
      <div>
        <input
          [ngClass]="{ error: error }"
          [(ngModel)]="newOrganization.Website"
        />
      </div>

      <div>
        <label>Email</label>
      </div>
      <div>
        <input
          [ngClass]="{ error: error }"
          [(ngModel)]="newOrganization.Email"
        />
      </div>
    </div>

    <br />
    <button (click)="add()">Add</button>
    <br />
    <a class="sub" (click)="cancel()">Cancel</a>
  </div>
  <p class="alert">{{ message }}</p>
  <mat-table #table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="Organization">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >ORGANIZATION</mat-header-cell
      >
      <mat-cell *matCellDef="let organization">
        {{ organization.Name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="LastUpdated">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >LAST UPDATED</mat-header-cell
      >
      <mat-cell *matCellDef="let organization">
        {{
          (organization.Timestamp?.Time > 1420095600000
            ? organization.Timestamp?.Time
            : 1420095600000
          ) | date: 'medium'
        }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Delete">
      <mat-header-cell *matHeaderCellDef class="align-right"></mat-header-cell>
      <mat-cell
        *matCellDef="let organization"
        (click)="$event.stopPropagation()"
      >
        <button mat-icon-button (click)="delete(organization.key)">
          <mat-icon style="color: gray;">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="goToOrganization(row.key)"
    ></mat-row>
  </mat-table>
</div>
