import { Component, OnInit, ViewChild } from '@angular/core';
import { Organization } from './organization';
import { Language, Program } from '../Models/program.model';
import { TranslateService } from '../firebase/translate.service';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';

@Component({
  selector: 'organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css'],
})
export class OrganizationsComponent implements OnInit {
  organizationsArray: Organization[] = [];
  programs: Program[] = [];
  message: string;
  adding = false;
  errorMessage: string;
  error = false;
  newOrganization: Organization = new Organization();
  languages: string[];
  translatedOrganization = {};
  translations: Language[] = []

  displayedColumns = ['Organization', 'LastUpdated', 'Delete'];
  dataSource: MatTableDataSource<Organization>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private organizationAccessor: OrganizationService,
    private programAccessor: ProgramService,
    private translateService: TranslateService,
    public auth: FirebaseAuthService
  ) {
    this.newOrganization = {
      Address: '',
      Call: '',
      Image: '',
      Name: '',
      Website: '',
      Email: '',
    };

    this.organizationAccessor
      .getUpdatableOrganizations()
      .snapshotChanges()
      .subscribe((organizations) => {
        this.organizationsArray = [];
        organizations.forEach((organization) => {
          let o: Organization = organization.payload.val();
          o.key = organization.key;
          this.organizationsArray.push(o);
        });
        this.dataSource = new MatTableDataSource(
          this.organizationsArray.sort((a, b) => {
            return a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0;
          })
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setSortingAccessor();
      });
    this.programs = this.programAccessor.getCachedPrograms();
  }

  async ngOnInit() {
    this.languages = await this.translateService.parseLanguages();

    for (const language of this.languages) {
      this.translatedOrganization[language] = {
        Name: '',
        userUpdated: false,
      };
    }
  }

  setSortingAccessor() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Organization': {
          return item.Name;
        }
        case 'LastUpdated': {
          return item.Timestamp ? item.Timestamp.Time : 1420095600000;
        }
      }
    };
    this.sort.direction = 'asc';
    this.sort.active = 'Name';
  }

  addingOrg() {
    this.adding = !this.adding;
    this.newOrganization.Name = '';
  }

  add() {
    this.newOrganization.Timestamp = {
      Time: Date.now(),
      Email: this.auth.user.email,
      Changes: '',
    };
    // clear white space
    this.newOrganization.Name = this.newOrganization.Name.trim();
    this.newOrganization.Website = this.newOrganization.Website.trim();
    this.newOrganization.Call = this.newOrganization.Call.trim();
    this.newOrganization.Address = this.newOrganization.Address.trim();
    this.newOrganization.Email = this.newOrganization.Email.trim();
    this.translatedOrganization['en'].Name = this.newOrganization.Name;

    this.languages.forEach((language) => {
      this.translations[language] = {
        name: this.translatedOrganization[language].Name,
        userUpdated: this.translatedOrganization[language].userUpdated
      };
    })

    if (this.translatedOrganization['en'].Name.length > 5000) {
      this.message =
        'Name must be less than 5000 characters';
      this.error = true;
    } else {
      let newOrganization = this.newOrganization;
      if (newOrganization.Name === undefined || newOrganization.Name === '') {
        this.errorMessage = 'Name is required';
        this.error = true;
      } else {
        this.organizationAccessor
          .getUpdatableOrganizations()
          .push(newOrganization)
          .then((snap) => {
            this.translateService.translateOrganizationByUserUpdated(
              newOrganization,
              snap.key,
              this.translations
            );
          });
        this.message = 'Success';
      }
    }

    this.errorMessage = '';
    this.error = false;
    this.adding = false;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  goToOrganization(key) {
    this.router.navigate([`/home/org-detail/${key}`]);
  }

  delete(value: string) {
    if (
      confirm(
        'Are you sure you want to delete this organization from the database?'
      )
    ) {
      if (!!this.programs.find((x) => x.OrganizationId == value)) {
        this.message = 'Organization cannot be deleted if in use';
      } else {
        this.message = 'Successfully deleted organization';
        this.organizationAccessor.getUpdatableOrganizations().remove(value);
        this.translateService.removeOrganization(value);
        this.organizationsArray = [];
      }
    }
  }

  cancel() {
    this.adding = false;
    this.errorMessage = '';
    this.error = false;
  }
}
