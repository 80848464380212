<div>
  <div class="search">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
    </mat-form-field>
  </div>

  <h3 class="header">
    Programs <a class="sub add" (click)="addingProgram()">Add Program</a> <ng-template [ngIf]="hasMissingCoordinates"> | <a class="sub add" (click)="addMissingCoordinates()">Add Missing Coordinates</a></ng-template>
  </h3>
  <div class="update" *ngIf="adding">
    <h4>Add Program</h4>
    <h5>* denotes a required field. Program name is required in english, but can be specified in additional languages
    </h5>
    <div>

      <label>English</label>
      <div class="textbox">
        <label>Program Name*</label>
      </div>

      <div class="textbox">
        <input [ngClass]="{ error: error }" [(ngModel)]="translations['en'].summary" />
      </div>
      <div class="textbox">
        <label>Description</label>
      </div>
      <div class="textbox">
        <textarea [ngClass]="{ error: error }" [(ngModel)]="translations['en'].description"></textarea>
      </div>

      <div *ngFor="let language of languages">
        <div *ngIf="language !== 'en' && translations[language]">
          <label>{{language}}:</label>
          <div class="textbox">
            <input class="checkbox" type="checkbox" [checked]="!translations[language]?.userUpdated"
            (change)="translations[language].userUpdated = !translations[language]?.userUpdated" 
            />
            Automatically Update
          </div>
          <div class="textbox">
            <label>Program Name</label>
          </div>

          <div class="textbox">
            <input [ngClass]="{ error: error }" [(ngModel)]="translations[language].summary" />
          </div>
          <div class="textbox">
            <label>Description</label>
          </div>
          <div class="textbox">
            <textarea [ngClass]="{ error: error }" [(ngModel)]="translations[language].description"></textarea>
          </div>
        </div>
      </div>

      <label>Organization*</label>

      <div class="textbox">
        <input type="text" list="orgList" (change)="getOrganizationKey($event)" />
        <datalist id="orgList">
          <select>
            <option *ngFor="let option of organizations" [value]="option.Name"></option>
          </select>
        </datalist>
      </div>


      <label>Category*</label>
      <div class="textbox">
        <input type="text" list="catList" (change)="getCategoryKey($event)" />
        <datalist id="catList">
          <select>
            <option *ngFor="let option of categories" [value]="option.Name"></option>
          </select>
        </datalist>
      </div>


      <label *ngIf="subcategories.length != 0">Subcategory</label>

      <div class="textbox">
        <input *ngIf="subcategories.length != 0" type="text" list="subCategoryList"
          (change)="getSubCategoryKey($event)" />
        <datalist id="subCategoryList">
          <select>
            <option *ngFor="let subcategory of subcategories" [value]="subcategory.Name"></option>
          </select>
        </datalist>

      </div>


      <label>Phone Number</label>
      <div class="textbox">
        <input [ngClass]="{ error: error }" [(ngModel)]="newProgram.Call" />
      </div>

      <label>Address</label>
      <div class="textbox">
        <input [ngClass]="{ error: error }" [(ngModel)]="newProgram.Address" />
      </div>

      <label>Email</label>
      <div class="textbox">
        <input [ngClass]="{ error: error }" [(ngModel)]="newProgram.Email" />
      </div>

      <label>Website URL</label>
      <div class="textbox">
        <input [ngClass]="{ error: error }" [(ngModel)]="newProgram.Website" />
      </div>
    </div>

    <br />
    <button (click)="add()">Add</button>
    <br />
    <a class="sub" (click)="cancel()">Cancel</a>
  </div>
  <p class="alert">{{ message }}</p>

  <mat-table #table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="Name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>NAME</mat-header-cell>
      <mat-cell *matCellDef="let program">
        {{ getDisplayName(program, 60) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Organization">
      <mat-header-cell *matHeaderCellDef mat-sort-header>ORGANIZATION</mat-header-cell>
      <mat-cell *matCellDef="let program">
        {{ getOrganization(program) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="LastUpdated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>LAST UPDATED</mat-header-cell>
      <mat-cell *matCellDef="let program">
        {{
          (program.Timestamp?.Time > 1420095600000
            ? program.Timestamp?.Time
            : 1420095600000
          ) | date: 'medium'
        }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Delete">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="align-right"></mat-header-cell>
      <mat-cell *matCellDef="let program" (click)="$event.stopPropagation()">
        <button mat-icon-button (click)="delete(program)">
          <mat-icon style="color: gray;">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="goToProgram(row)"></mat-row>
  </mat-table>
</div>
