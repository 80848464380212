import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subcategory } from './category';
import { TranslateService } from '../firebase/translate.service';
import { SubcategoryService } from 'app/Services/subcategory.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'edit-subcategory',
  templateUrl: './editSubcategory.component.html',
  styleUrls: ['./editCategory.component.css'],
})
export class EditSubcategoryComponent implements OnInit {
  categoryId: string;
  subcategoryId: string;
  subcategoryEnglish: Observable<Subcategory>;
  subcategorySpanish: Observable<Subcategory>;
  subcategoryVietnamese: Observable<Subcategory>;
  subcategoryArabic: Observable<Subcategory>;
  subcategorySomali: Observable<Subcategory>;
  subcategoryUkrainian: Observable<Subcategory>;
  subcategoryDari: Observable<Subcategory>;
  subcategoryPashtu: Observable<Subcategory>;
  subcategoryKurdish: Observable<Subcategory>;
  subcategoryKurmanji: Observable<Subcategory>;

  translatedSubcategories = {};
  languages: string[];
  error = false;

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslateService,
    private subcategoryAccessor: SubcategoryService
  ) {
    this.route.params.subscribe((params) => {
      this.subcategoryId = params['subcatId'];
      this.categoryId = params['catId'];
      this.subcategoryEnglish = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId
      );
      this.subcategoryArabic = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'ar'
      );
      this.subcategorySpanish = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'es'
      );
      this.subcategorySomali = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'so'
      );
      this.subcategoryVietnamese = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'vi'
      );
      this.subcategoryUkrainian = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'uk'
      );
      this.subcategoryDari = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'prs'
      );
      this.subcategoryPashtu = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'ps'
      );
      this.subcategoryKurdish = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'ku'
      );
      this.subcategoryKurmanji = this.subcategoryAccessor.getSubcategoryById(
        this.categoryId,
        this.subcategoryId,
        'kmr'
      );
    });
  }

  updateAndTranslateSubcategories() {
    this.languages.forEach(async (language) => {
      var updatedSubategory: Subcategory;

      if(language === 'en' || !this.translatedSubcategories[language].userUpdated){
        updatedSubategory = {
          Name: this.translatedSubcategories[language].Name.trim(),
          userUpdated: this.translatedSubcategories[language].userUpdated || null
        };
      }
      else
      {
        var updatedName = await this.translationService.translateString(language, this.translatedSubcategories['en'].Name.trim());

        if(!updatedName){
          updatedName = '';
        }

        updatedSubategory = {
          Name: updatedName,
          userUpdated: this.translatedSubcategories[language].userUpdated || null
        };
      }

      this.subcategoryAccessor.updateSubcategory(this.categoryId, this.subcategoryId, updatedSubategory, language);

    });

  }

  async ngOnInit() {
    this.languages = await this.translationService.parseLanguages();

    for (const language of this.languages) {
      this.translatedSubcategories[
        language
      ] = await this.subcategoryAccessor.getSingleSubcategory(this.categoryId, this.subcategoryId, language);

      if(this.translatedSubcategories[language] && !this.translatedSubcategories[language].userUpdated) {
        // If program exists in language but doesn't have userUpdated boolean set Automatically Updated checkbox default to true 
        this.translatedSubcategories[language].userUpdated = true;
      }
      if (!this.translatedSubcategories[language]) {
        // If program doesn't exist in language, set display values for edit page
        this.translatedSubcategories[language] = {
          Name: '',
          userUpdated: false
        };
      }
    }
  }
}
