<script src="//cdnjs.cloudflare.com/ajax/libs/moment.js/2.7.0/moment.min.js" type="text/javascript"></script>
<h3>
  Program Details
  <span class="sub">
    <a class="edit" (click)="toEditProgram()">Edit Program</a> |
    <a class="delete" (click)="delete()">Delete Program</a>
  </span>
</h3>

<h4 style="font-weight: 100;">
  Last verification/change made on
  <strong>{{ program?.Timestamp?.Time | date: 'medium' }}</strong> by
  <strong>{{ program?.Timestamp?.Email }}</strong>
</h4>

<div class="content">
  <button class="verify" (click)="verifyProgramInformation()">
    Verify information
  </button>
  <dt>Program Name</dt>
  <dd>{{ program?.Summary || '(none)' }}</dd>
  <dt>Organization</dt>
  <dd>{{ (associatedOrganization | async)?.Name || '(none)' }}</dd>
  <dt>Phone Number</dt>
  <dd>{{ program?.Call || '(none)' }}</dd>
  <dt>Address</dt>
  <dd>{{ program?.Address || '(none)' }}</dd>
  <dt>Website</dt>
  <dd>{{ program?.Website || '(none)' }}</dd>
  <dt>Email</dt>
  <dd>{{ program?.Email || '(none)' }}</dd>
  <dt>Description</dt>
  <dd>{{ program?.Description || '(none)' }}</dd>
  <dt>Category</dt>
  <dd>{{ (associatedCategory | async)?.Name || '(none)' }}</dd>
  <dt>Subcategory</dt>
  <dd *ngIf="associatedSubcategories.length === 0">(none)</dd>
  <dd *ngFor="let subcategory of associatedSubcategories">
    <p>{{ subcategory?.Name }}</p>
  </dd>
  <dt>Translations:</dt>
  <dd *ngFor="let language of languages">
    <strong>{{language}}: </strong>
    <br />Program Name: {{ translatedSummaries[language] || '(none)' }}
    <br />Description: {{ translatedDescriptions[language] || '(none)' }}
  </dd>
</div>

<div class="content">
  <dt class="schedule">
    Schedules:
    <a class="sub add" (click)="add()">Add Schedule</a>
  </dt>
  <div>
    <dd class="schedule" *ngFor="let schedule of programSchedules">
      <span>
        Cycle: {{ schedule.Cycle }} <br />
        Day: {{ schedule.Day }} <br />
        Time: {{ displaySchedule(schedule.StartTime, schedule.EndTime) }} <br />
        <a class="delete sub" (click)="deleteEvent(schedule.key)">Delete</a> |
        <a class="edit sub" routerLink="/home/editSchedule/{{ schedule.key }}" routerLinkActive="active">Edit
          Schedule</a>
        <br />
        <div *ngIf="schedule.Timestamp">
          Last change made on
          <strong>{{ schedule.Timestamp?.Time | date: 'medium' }}</strong> by
          <strong>{{ schedule.Timestamp?.Email }}</strong>
        </div>
      </span>
    </dd>
  </div>
  <br />
  <div class="update" *ngIf="adding">
    <h4>Add Schedule</h4>
    <p class="alert">{{ cycleMessage }}</p>
    <p class="alert">{{ dayMessage }}</p>
    <dt>Cycle*</dt>
    <dd>
      <select [ngClass]="{ error: error }" [(ngModel)]="cycle">
        <option *ngFor="let cycle of cycles" [value]="cycle">{{
          cycle
        }}</option>
      </select>
    </dd>
    <dt>Day*</dt>
    <dd>
      <select [ngClass]="{ error: error }" [(ngModel)]="day">
        <option *ngFor="let day of days" [value]="day">{{ day }}</option>
      </select>
    </dd>
    <dt *ngIf="!this.isSafari">Start Time (Ex 8:00 AM)</dt>
    <dt *ngIf="this.isSafari">Start Time: Please enter start time in Military Time (Ex 08:00)</dt>
    <dd>
      <input [ngClass]="{ error: error }" type="time" [(ngModel)]="startTime" />
    </dd>
    <dt *ngIf="!this.isSafari">End Time (Ex 05:00 PM)</dt>
    <dt *ngIf="this.isSafari">End Time: Please enter end time in Military Time (Ex 17:00)</dt>
    <dd>
      <input type="time" [(ngModel)]="endTime" />
    </dd>
    <button (click)="addEvent()">Add</button>
    <br />
    <a class="sub" (click)="cancel()">Cancel</a>
  </div>
</div>

<button routerLink="/home/program-list" routerLinkActive="active">
  Back to Programs
</button>