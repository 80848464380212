<h3>Edit SubCategory</h3>

<div class="content">

  <div *ngIf="translatedSubcategories['en']">
    <dt>
      <label>English:</label>
    </dt>
    <dd>
      <label>Subcategory Name</label>
    </dd>
    <dd>
      <input type="text" *ngIf="true" [ngClass]="{ error: error }" [(ngModel)]="translatedSubcategories['en'].Name" (change)="isNameChanged = true" />
    </dd>
  </div>
  
  
  <div *ngFor="let language of languages">
    <div *ngIf="language !== 'en' && translatedSubcategories[language]">
      <dt>
        <label>{{language}}:</label>
      </dt>
    
      <dd>
        <input class="checkbox" type="checkbox" [checked]="translatedSubcategories[language]?.userUpdated"
          (change)="translatedSubcategories[language].userUpdated = !translatedSubcategories[language]?.userUpdated" />
        Automatically Update
      </dd>
      <dd>
        <label>Subcategory Name</label>
      </dd>
      <dd>
        <input type="text" [ngClass]="{ error: error }" [(ngModel)]="translatedSubcategories[language].Name" />
      </dd>
    </div>
  
  </div>
</div>
<br />
<span>
  <button
    routerLink="/home/subProgram/{{ categoryId }}/{{ subcategoryId }}"
    routerLinkActive="active"
    class="cancel"
  >
    Cancel
  </button>
  <button
    routerLink="/home/subProgram/{{ categoryId }}/{{ subcategoryId }}"
    routerLinkActive="active"
    (click)="updateAndTranslateSubcategories()"
    class="submit-update"
  >
    Update
  </button>
</span>
