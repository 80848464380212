import { Injectable } from '@angular/core';
import { LocationNameMapper, LocationCodeMapper } from '../Models/enums/location';

@Injectable({
    providedIn: 'root',
})
export class PreferencesService {
    constructor() {
    }

    public setLocation(location: number): void {
        localStorage.setItem('location', location.toString());
    }

    public getLocationId(): number {
        return Number(localStorage.getItem('location'));
    }

    public getLocationName(): string {
        return LocationNameMapper[localStorage.getItem('location')];
    }

    public getLocationCode(): string {
        return LocationCodeMapper[localStorage.getItem('location')];
    }

}
