import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { User } from '../admin/user';
import { DataService } from './data.service';
import { ObjectCache } from '../Models/objectCache.model';
import { Admin } from '../admin/admin';

@Injectable({
  providedIn: 'root',
})
export class UserService extends DataService<User> {
  users: AngularFireList<User>;
  admins: AngularFireList<Admin>;

  adminList: Admin[];
  userList: User[];

  constructor(private db: AngularFireDatabase) {
    super(db, 'users', new ObjectCache<User>());
  }

  getUpdatableUsers(lang?: string): AngularFireList<User> {
    return super.getAngularFireObjects(lang);
  }

  initializeLists() {
    this.users = this.findList('users', 'email');
    this.admins = this.findUnorderedList('admins');
  }

  findUnorderedList(path: string): AngularFireList<any> {
    let items: AngularFireList<any[]>;
    items = this.db.list(path, (ref) => ref.orderByKey());
    return items;
  }

  findList(path: string, order: string): AngularFireList<any> {
    let items: AngularFireList<any[]>;
    items = this.db.list(path, (ref) => ref.orderByChild(order));
    return items;
  }

  findOne(path: string, id: string): AngularFireList<any[]> {
    const route = path + '/' + id;
    let item: AngularFireList<any[]>;
    item = this.db.list(path, (ref) => ref.orderByKey().equalTo(id));
    return item;
  }

  checkAdmin() {
    let items: AngularFireList<Admin>;
    const admins: Admin[] = [];
    items = this.db.list('/admins', (ref) => ref.orderByKey());
    items.valueChanges().subscribe((snapshots) => {
      snapshots.forEach((snapshot) => {
        const admin: Admin = snapshot;
        admin['key'] = snapshot.key;
        admins.push(admin);
      });
    });
    this.adminList = admins;
  }

  async findUsers(): Promise<any> {
    var ref = DataService.dbRef.ref('/users');
    return (await ref.once('value')).val();
  }

  async parseUser(): Promise<User[]> {
    const result = await this.findUsers();
    let results: User[] = [];
    for (let i in result) {
      results.push(result[i]);
    }
    return results;
  }

  updateUserList(value: User, admin: boolean) {
    this.users.set(value.uid, value);
    if (admin) {
      this.admins.push({ uid: value.uid });
    }
  }

  deleteUser(key: string, adminKey: string) {
    this.users.remove(key);
    if (adminKey !== undefined) {
      this.admins.remove(adminKey);
    }
  }
}
